import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.repoType !== 'endpoint')
      ? (_openBlock(), _createBlock($setup["RepoClone"], {
          key: 0,
          repoType: $props.repoType,
          httpCloneUrl: $props.repoDetail.repository?.http_clone_url || '',
          sshCloneUrl: $props.repoDetail.repository?.ssh_clone_url || '',
          userName: $props.userName,
          namespacePath: $props.path,
          admin: $props.admin,
          repo: $props.repoDetail,
          enableEndpoint: $props.repoDetail.enable_inference,
          enableFinetune: $props.repoDetail.enable_finetune
        }, null, 8 /* PROPS */, ["repoType", "httpCloneUrl", "sshCloneUrl", "userName", "namespacePath", "admin", "repo", "enableEndpoint", "enableFinetune"]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["TabContainer"], {
      "default-tab": $props.defaultTab,
      settingsVisibility: $props.settingsVisibility,
      repoType: $props.repoType,
      localRepoId: $props.localRepoId,
      sdk: $props.sdk,
      repo: $props.repoDetail,
      onTabChange: $setup.tabChange
    }, _createSlots({
      summary: _withCtx(() => [
        _createCommentVNode(" <ApplicationPage\n          v-if=\"repoType === 'space' && repoDetail.path === 'leaderboard/SuperClueRanking'\"\n          appEndpoint=\"https://www.superclueai.com/\"\n        />\n        <InitializeGuide\n          v-else-if=\"repoType === 'space' && appStatus === 'NoAppFile'\"\n          :http-clone-url=\"repoDetail.repository?.http_clone_url || ''\"\n          :ssh-clone-url=\"repoDetail.repository?.ssh_clone_url || ''\"\n          :sdk=\"sdk\"\n          :user-name=\"userName\"\n        />\n        <ApplicationPage\n          v-else-if=\"repoType === 'space' && appStatus === 'Running'\"\n          :appEndpoint=\"appEndpoint\"\n        />\n        <StoppedPage\n          v-else-if=\"repoType === 'space' && (appStatus === 'Stopped' || appStatus === 'Sleeping')\"\n          :appStatus=\"appStatus\"\n          :canWrite=\"canWrite\"\n          :path=\"path\"\n        />\n        <BuildAndErrorPage\n          v-else-if=\"repoType === 'space'\"\n          :appStatus=\"appStatus\"\n          :canWrite=\"canWrite\"\n          @showSpaceLogs=\"showSpaceLogs\"\n        />\n        <EndpointPage\n          v-else-if=\"repoType === 'endpoint'\"\n          :appEndpoint=\"appEndpoint\"\n          :appStatus=\"appStatus\"\n          :modelId=\"modelId\"\n          :private=\"private\"\n          :endpointReplica=\"endpointReplica\"\n          :hardware=\"hardware\"\n          :replicaList=\"replicaList\"\n        /> "),
        _createVNode($setup["RepoSummary"], {
          "repo-type": $props.repoType,
          "namespace-path": $props.path,
          "download-count": $props.repoDetail.downloads,
          currentBranch: $props.currentBranch,
          "widget-type": $props.repoDetail.widget_type,
          license: $props.repoDetail.license
        }, null, 8 /* PROPS */, ["repo-type", "namespace-path", "download-count", "currentBranch", "widget-type", "license"])
      ]),
      community: _withCtx(() => [
        _createVNode($setup["CommunityPage"], {
          repoType: $props.repoType,
          repoPath: $props.repoDetail.path
        }, null, 8 /* PROPS */, ["repoType", "repoPath"])
      ]),
      _: 2 /* DYNAMIC */
    }, [
      ($props.actionName === 'blob')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["Blob"], {
                branches: _ctx.branches,
                "current-branch": $props.currentBranch,
                "current-path": $props.currentPath,
                "namespace-path": $props.path,
                "can-write": $props.canWrite
              }, null, 8 /* PROPS */, ["branches", "current-branch", "current-path", "namespace-path", "can-write"])
            ]),
            key: "0"
          }
        : undefined,
      ($props.actionName === 'new_file')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["NewFile"], {
                "current-branch": $props.currentBranch,
                "repo-name": $props.repoDetail.name,
                "namespace-path": $props.path,
                originalCodeContent: ""
              }, null, 8 /* PROPS */, ["current-branch", "repo-name", "namespace-path"])
            ]),
            key: "1"
          }
        : undefined,
      ($props.actionName === 'edit_file')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["EditFile"], {
                "current-branch": $props.currentBranch,
                "current-path": $props.currentPath,
                "repo-name": $props.repoDetail.name,
                "namespace-path": $props.path
              }, null, 8 /* PROPS */, ["current-branch", "current-path", "repo-name", "namespace-path"])
            ]),
            key: "2"
          }
        : undefined,
      ($props.actionName === 'upload_file')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["UploadFile"], {
                "current-branch": $props.currentBranch,
                "repo-name": $props.repoDetail.name,
                "namespace-path": $props.path
              }, null, 8 /* PROPS */, ["current-branch", "repo-name", "namespace-path"])
            ]),
            key: "3"
          }
        : undefined,
      ($props.actionName === 'commits')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["RepoCommits"], {
                branches: _ctx.branches,
                currentBranch: $props.currentBranch,
                namespacePath: $props.path,
                repoType: $props.repoType
              }, null, 8 /* PROPS */, ["branches", "currentBranch", "namespacePath", "repoType"])
            ]),
            key: "4"
          }
        : undefined,
      ($props.actionName === 'commit')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["RepoCommit"], {
                namespacePath: $props.path,
                repoType: $props.repoType,
                commitId: $props.commitId
              }, null, 8 /* PROPS */, ["namespacePath", "repoType", "commitId"])
            ]),
            key: "5"
          }
        : undefined,
      ($props.actionName === 'files')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["RepoFiles"], {
                branches: _ctx.branches,
                "current-branch": $props.currentBranch,
                "current-path": $props.currentPath,
                "namespace-path": $props.path,
                "can-write": $props.canWrite,
                "repo-type": $props.repoType
              }, null, 8 /* PROPS */, ["branches", "current-branch", "current-path", "namespace-path", "can-write", "repo-type"])
            ]),
            key: "6"
          }
        : undefined,
      ($props.settingsVisibility)
        ? {
            name: "settings",
            fn: _withCtx(() => [
              ($props.repoType === 'model')
                ? (_openBlock(), _createBlock($setup["ModelSettings"], {
                    key: 0,
                    path: $props.path,
                    "model-nickname": $props.repoDetail.nickname,
                    "model-desc": $props.repoDetail.description,
                    default_branch: $props.repoDetail.default_branch,
                    "tag-list": $props.tagList,
                    tags: $props.tags
                  }, null, 8 /* PROPS */, ["path", "model-nickname", "model-desc", "default_branch", "tag-list", "tags"]))
                : _createCommentVNode("v-if", true),
              ($props.repoType === 'dataset')
                ? (_openBlock(), _createBlock($setup["DatasetSettings"], {
                    key: 1,
                    path: $props.path,
                    "dataset-nickname": $props.repoDetail.nickname,
                    "dataset-desc": $props.repoDetail.description,
                    default_branch: $props.repoDetail.default_branch,
                    "tag-list": $props.tagList,
                    tags: $props.tags
                  }, null, 8 /* PROPS */, ["path", "dataset-nickname", "dataset-desc", "default_branch", "tag-list", "tags"]))
                : _createCommentVNode("v-if", true),
              ($props.repoType === 'code')
                ? (_openBlock(), _createBlock($setup["CodeSettings"], {
                    key: 2,
                    path: $props.path,
                    "code-nickname": $props.repoDetail.nickname,
                    "code-desc": $props.repoDetail.description,
                    default_branch: $props.repoDetail.default_branch
                  }, null, 8 /* PROPS */, ["path", "code-nickname", "code-desc", "default_branch"]))
                : _createCommentVNode("v-if", true),
              _createCommentVNode(" <application-space-settings\n          v-if=\"repoType === 'space'\"\n          :path=\"path\"\n          :application-space-nickname=\"repoDetail.nickname\"\n          :application-space-desc=\"repoDetail.description\"\n          :default_branch=\"repoDetail.default_branch\"\n          :appStatus=\"appStatus\"\n          :cloudResource=\"repoDetail.sku || repoDetail.hardware\"\n          @showSpaceLogs=\"showSpaceLogs\"\n          :coverImage=\"repoDetail.cover_image_url\"\n          :isAdmin=\"admin\"\n        />\n        <EndpointSettings\n          v-if=\"repoType === 'endpoint'\"\n          :endpointName=\"endpointName\"\n          :endpointId=\"endpointId\"\n          :appStatus=\"appStatus\"\n          :modelId=\"modelId\"\n          :userName=\"userName\"\n          :cloudResource=\"repoDetail.sku || repoDetail.hardware\"\n          :framework=\"repoDetail.runtime_framework\"\n          :maxReplica=\"repoDetail.max_replica\"\n          :minReplica=\"repoDetail.min_replica\"\n        /> ")
            ]),
            key: "7"
          }
        : undefined
    ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["default-tab", "settingsVisibility", "repoType", "localRepoId", "sdk", "repo"])
  ]))
}