import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "max-w-[1440px] m-auto flex md:px-[16px] px-[32px]" }
const _hoisted_3 = { class: "flex md:flex-col w-full" }
const _hoisted_4 = { class: "flex pt-[24px] pr-[24px] w-[310px] flex-col gap-[16px] border-r border-r-[1px] border-[#DCDFE6] mb-[40px]" }
const _hoisted_5 = { class: "flex flex-col gap-[8px]" }
const _hoisted_6 = { class: "text-[12px] font-[400] leading-[20px] text-[#909399]" }
const _hoisted_7 = { class: "flex gap-[8px] flex-wrap" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "flex flex-col gap-[8px]" }
const _hoisted_10 = { class: "text-[12px] font-[400] leading-[20px] text-[#909399]" }
const _hoisted_11 = { class: "flex gap-[8px] flex-wrap" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "grow flex flex-col mb-[40px]" }
const _hoisted_15 = { class: "flex w-full justify-between h-[60px] items-center border-b md:border-0 md:gap-[16px]" }
const _hoisted_16 = { class: "px-[16px] md:px-0 md:w-[222px]" }
const _hoisted_17 = { class: "px-[16px] md:px-0 search-model-input md:border-0 py-[12px] border-[#F2F2F2] css-custom-input" }
const _hoisted_18 = {
  href: "/organizations/new",
  class: "btn-orange px-[24px] py-[8px] rounded-[4px] text-[14px] font-[400] leading-[20px] text-[#FFFFFFE6] cursor-pointer"
}
const _hoisted_19 = { class: "md:border-b md:pb-[12px]" }
const _hoisted_20 = { class: "flex flex-col gap-[8px]" }
const _hoisted_21 = { class: "text-[12px] font-[400] leading-[20px] text-[#909399]" }
const _hoisted_22 = { class: "flex gap-[8px] flex-wrap" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "text-[12px] font-[400] leading-[20px] text-[#909399]" }
const _hoisted_25 = { class: "flex gap-[8px] flex-wrap" }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = ["src"]
const _hoisted_28 = { class: "grid grid-cols-2 md:grid-cols-1 gap-[16px] pl-[24px] md:pl-0 py-[24px]" }
const _hoisted_29 = { class: "pt-[20px] flex justify-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_drawer = _resolveComponent("el-drawer")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createCommentVNode(" 左侧标签选择模块 "),
        (!$data.mobileScreen)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createCommentVNode(" PC 端 "),
              _createElementVNode("div", _hoisted_4, [
                _createCommentVNode(" 组织类型 "),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("organization.collection.org_type")), 1 /* TEXT */),
                  _createElementVNode("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.org_types, (org_type) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(`flex gap-[4px] border border-[1px] border-[#D0D5DD] px-[8px] py-[3px] rounded-[4px] text-[#344054] text-[12px] font-[400] leading-[18px] cursor-pointer ${$data.query.org_type == org_type ? 'active-org-tag-btn' : ''}`),
                        key: org_type,
                        onClick: $event => ($options.handleOrgTypeChange(org_type))
                      }, _toDisplayString(org_type), 11 /* TEXT, CLASS, PROPS */, _hoisted_8))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]),
                _createCommentVNode(" 组织行业 "),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("organization.collection.industry")), 1 /* TEXT */),
                  _createElementVNode("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.industries, (industry) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(`flex gap-[4px] border border-[1px] border-[#D0D5DD] px-[8px] py-[3px] rounded-[4px] text-[#344054] text-[12px] font-[400] leading-[18px] cursor-pointer ${$data.query.industry == industry ? 'active-org-tag-btn' : ''}`),
                        key: industry,
                        onClick: $event => ($options.handleIndustryChange(industry))
                      }, [
                        _createElementVNode("img", {
                          src: `/images/xty_images/organization/collection/${industry}.svg`,
                          alt: ""
                        }, null, 8 /* PROPS */, _hoisted_13),
                        _createTextVNode(" " + _toDisplayString(industry), 1 /* TEXT */)
                      ], 10 /* CLASS, PROPS */, _hoisted_12))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ])
              ])
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(" 右侧内容模块 "),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_15, [
              _createCommentVNode(" 搜索框 "),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_el_input, {
                    modelValue: $data.query.search,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.query.search) = $event)),
                    "prefix-icon": $setup.Search,
                    onChange: $options.handleKeywordChange,
                    type: "text",
                    placeholder: _ctx.$t('organization.collection.search_placeholder')
                  }, null, 8 /* PROPS */, ["modelValue", "prefix-icon", "onChange", "placeholder"])
                ])
              ]),
              _createCommentVNode(" 新建组织 "),
              _createElementVNode("div", null, [
                _createElementVNode("a", _hoisted_18, _toDisplayString(_ctx.$t("organization.collection.create_organization")), 1 /* TEXT */)
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              ($data.mobileScreen)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("span", {
                      class: _normalizeClass(`${$data.org_type_drawer ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`),
                      onClick: _cache[1] || (_cache[1] = $event => ($data.org_type_drawer = true))
                    }, " 组织类型 ", 2 /* CLASS */),
                    _createElementVNode("span", {
                      class: _normalizeClass(`${$data.industry_drawer ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`),
                      onClick: _cache[2] || (_cache[2] = $event => ($data.industry_drawer = true))
                    }, " 组织行业 ", 2 /* CLASS */),
                    _createCommentVNode(" 组织类型 "),
                    _createVNode(_component_el_drawer, {
                      modelValue: $data.org_type_drawer,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.org_type_drawer) = $event)),
                      direction: "btt",
                      "with-header": false
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("organization.collection.org_type")), 1 /* TEXT */),
                          _createElementVNode("div", _hoisted_22, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.org_types, (org_type) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: _normalizeClass(`flex gap-[4px] border border-[1px] border-[#D0D5DD] px-[8px] py-[3px] rounded-[4px] text-[#344054] text-[12px] font-[400] leading-[18px] cursor-pointer ${$data.query.org_type == org_type ? 'active-org-tag-btn' : ''}`),
                                key: org_type,
                                onClick: $event => ($options.handleOrgTypeChange(org_type))
                              }, _toDisplayString(org_type), 11 /* TEXT, CLASS, PROPS */, _hoisted_23))
                            }), 128 /* KEYED_FRAGMENT */))
                          ])
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"]),
                    _createCommentVNode(" 组织行业 "),
                    _createVNode(_component_el_drawer, {
                      modelValue: $data.industry_drawer,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.industry_drawer) = $event)),
                      direction: "btt",
                      "with-header": false
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t("organization.collection.industry")), 1 /* TEXT */),
                        _createElementVNode("div", _hoisted_25, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.industries, (industry) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: _normalizeClass(`flex gap-[4px] border border-[1px] border-[#D0D5DD] px-[8px] py-[3px] rounded-[4px] text-[#344054] text-[12px] font-[400] leading-[18px] cursor-pointer ${$data.query.industry == industry ? 'active-org-tag-btn' : ''}`),
                              key: industry,
                              onClick: $event => ($options.handleIndustryChange(industry))
                            }, [
                              _createElementVNode("img", {
                                src: `/images/xty_images/organization/collection/${industry}.svg`,
                                alt: ""
                              }, null, 8 /* PROPS */, _hoisted_27),
                              _createTextVNode(" " + _toDisplayString(industry), 1 /* TEXT */)
                            ], 10 /* CLASS, PROPS */, _hoisted_26))
                          }), 128 /* KEYED_FRAGMENT */))
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"]),
                    _createCommentVNode(" 手机端 ")
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _createCommentVNode(" 组织列表 "),
          _createElementVNode("div", _hoisted_28, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items, (item) => {
              return (_openBlock(), _createBlock($setup["OrganizationCard"], {
                key: item.id,
                item: item
              }, null, 8 /* PROPS */, ["item"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createElementVNode("div", _hoisted_29, [
            _createVNode($setup["CsgPagination"], {
              perPage: $data.query.per,
              currentPage: $data.query.page,
              onCurrentChange: $options.fetchItem,
              total: $data.totalCount
            }, null, 8 /* PROPS */, ["perPage", "currentPage", "onCurrentChange", "total"])
          ])
        ])
      ])
    ])
  ]))
}