export const form = {
  "contactUs": {
    "contact": "联系我们",
    "name": "名字",
    "company": "公司 请务必填写工商信息名称!",
    "email": "邮箱",
    "phone": "手机",
    "job": "职位",
    "help": "你在寻找什么帮助",
    "submit": "提交",
    "close": "关闭",
  },
  "expert": {
    "tip": "表单中填写的信息除电话号码外将展示在专家推荐页面",
    "p1": "开放传神（CAICT）邀请您入驻专家社区，让我们共建开源开放、共享开源生态。希望您为用户提供技术支持及咨询，解决方案设计指导等。",
    "p2": "您可以是人工智能专家，软件开发专家，开源专家，算法专家，法务专家等。诚挚的欢迎您入驻专家社区。",
    "name": "姓名",
    "job": "职位名称",
    "phone": "电话号码",
    "company": "公司名称",
    "expertise": "擅长领域",
    "desc": "个人介绍",

    "nameWarn": "请您填写姓名",
    "jobWarn": "请您填写职位名称",
    "phoneWarn": "请您填写电话号码",
    "companyWarn": "请您填写公司名称",
    "expertiseWarn": "请您填写擅长领域",
    "descWarn": "请您填写个人介绍",
    "success": "表单发送成功",
  },
  "partner": {
    "p1": "如您有意加入CAICT开放传神社区并成为我们的合作伙伴，请填写下方信息",
    "p2": "我们会尽快与您联系并详细沟通，感谢您对CAICT的支持。",
    "name": "姓名",
    "name-": "你的名字",
    "phone": "手机号码",
    "email": "工作电子邮箱地址",
    "company": "公司/单位名称",
    "company-": "开放传神",
    "expertise": "职称",
    "expertise-": "首席执行官",
    "web": "公司/产品官网",
    "web-": "例：https://caict.com",
    "industry": "公司行业",
    "industry-": "请填写您的行业信息",
    "desc": "请简要描述您的合作需求",
    "desc-": "请提供您的合作意向及需求",
    "submit": "提交表单",

    "nameWarn": "请您填写姓名",
    "phoneWarn": "请您填写电话号码",
    "emailWarn": "请您填写邮箱",
    "companyWarn": "请您填写公司/单位名称",
    "expertiseWarn": "请您填写职称",
    "webWarn": "请您填写公司/产品官网",
    "industryWarn": "请您选择公司行业",
  }
}
