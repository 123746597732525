<template>
  <div v-if="show" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white rounded-lg p-6 max-w-md w-full mx-4">
      <div class="text-xl font-medium mb-4">系统公告</div>
      <div class="text-gray-600 mb-6">
        尊敬的用户，我们的服务正在进行更新维护，期间请不要进行用户注册和上传数据（包括但不限于创建组织、修改资源或用户数据等），部分功能可能会出现不稳定的情况。给您带来的不便，敬请谅解。
      </div>
      <div class="flex justify-end">
        <button
            @click="closeAnnouncement"
            class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          我知道了
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true
    }
  },
  methods: {
    closeAnnouncement() {
      this.show = false
      sessionStorage.setItem('hasShownAnnouncement', 'true')
    }
  },
  mounted() {
    const hasShownAnnouncement = sessionStorage.getItem('hasShownAnnouncement')
    if (hasShownAnnouncement) {
      this.show = false
    }
  }
}
</script>