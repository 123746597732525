export const datasets = {
  title: "数据集",
  list: "数据集列表",
  detail: "数据集详情",
  placeholder: "数据集名字",
  downloadDataset: "下载数据集",
  datasetName: "数据集名称",
  datasetNameTips: "应用于数据集路径，创建后不可更改",
  namespaceDatasetName: "命名空间/数据集名称",
  datasetNickName: "数据集别名",
  datasetDesc: "数据集简介",
  datasetTag: "数据集标签",
  datasetIndustryTag: "数据集行业标签",
  tips:"轻松的托管数据集",
  datasetsUsedToTrain: "关联数据集",
  newDataset: {
    title: "新建数据集仓库",
    titleDesc: "仓库包含所有的模型文件和修订的历史记录",
    owner: "所有者",
    datasetName: "数据集名称",
    datasetNickName: "数据集别名",
    datasetDesc: "数据集简介",
    public: "公开",
    publicDesc:
      "任何互联网上的人都可以看到这个仓库。只有你（个人）或你所在组织的成员可以提交。",
    private: "私有",
    privateDesc: "只有你（个人）或你所在组织的成员可以看到并提交到这个仓库。",
    tips: "创建数据集后，你可以使用网页或 Git 上传你的文件。",
    tips2: "暂不支持创建公开的数据集，如有需要可以联系管理员进行人工审核。",
    tips3: " 选择数据集对应的分类标签，便于用户在筛选时更快的找到您的数据集。",
    createDataset: "创建数据集",
    createSuccess: "数据集创建成功",
    tip: "应用于数据集数据，创建后不可更改",
    datasetEnName: "数据集英文名称",
    license: "许可证",
    createFail: "数据集创建失败"
  },
  edit: {
    tips: "将会显示在数据集列表页面中，选填",
    tips2: "一段关于数据集的介绍，选填",
    tips3: " 选择数据集对应的分类标签，便于用户在筛选时更快的找到您的数据集。",
    tips4: " 选择数据集对应的行业标签，便于用户在筛选时更快的找到您的数据集。",
    changeVisibility: "修改数据集可见性",
    statusText: "当前数据集目前是",
    status: "状态。",
    datasetVisibility: "数据集可见性",
    delDataset: "删除数据集",
    canNot: "无法",
    delTips: "此操作",
    delTips2: "撤销，这将永久删除",
    delTips3: "数据集及其所有文件。",
    enterPls: "请输入",
    sureDel: "确认删除",
    privateVis: "只有创建者或组织成员可见",
    publicVis: "任何人都可以看到此数据集",
    confirmDel: "我已知晓,确认删除",
    privateInfo: "，仅创建者或者组织管理员可进行提交",
    publicInfo: "， 任何互联网上的人都可以看到此数据集。",
    needDatasetName: "请先提供数据集别名",
    needDatasetDesc: "请先提供数据集介绍",
    needDatasetTag: '请先提供数据集标签'
  },
}