import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "border border-[#DCDFE6] rounded-[8px] my-[32px] md:my-0 md:border-none px-[24px] py-[24px]" }
const _hoisted_2 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_3 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_4 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_5 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_6 = { class: "flex flex-col gap-[6px]" }
const _hoisted_7 = { class: "text-[14px] text-[#475467]" }
const _hoisted_8 = { class: "w-[512px] sm:w-full rounded-[8px] bg-[#F9FAFB] px-[14px] py-[10px] border" }
const _hoisted_9 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_10 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_11 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_12 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_13 = { class: "flex flex-col gap-[6px]" }
const _hoisted_14 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_15 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_16 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_17 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_18 = { class: "flex flex-col gap-[6px]" }
const _hoisted_19 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_20 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_21 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_22 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_23 = {
  class: "flex flex-col gap-[6px]",
  ref: "tagListContainer"
}
const _hoisted_24 = { class: "text-[#344054] text-[14px]" }
const _hoisted_25 = { class: "flex flex-col gap-[6px] w-[512px] md:w-full" }
const _hoisted_26 = { class: "flex gap-[4px] flex-wrap items-center w-full border rounded-[4px] border-gray-300 min-h-[40px] p-[6px]" }
const _hoisted_27 = { class: "scroll-container flex gap-[4px] flex-wrap max-h-[120px] overflow-y-auto" }
const _hoisted_28 = { class: "flex items-center gap-[5px] border rounded-[5px] border-gray-300 px-[5px] py-[2px]" }
const _hoisted_29 = { class: "rounded-md max-h-[300px] overflow-y-auto border border-gray-200 bg-white shadow-lg py-[4px] px-[6px]" }
const _hoisted_30 = ["onClick"]
const _hoisted_31 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_32 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_33 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_34 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_35 = {
  class: "flex flex-col gap-[6px]",
  ref: "IndustryTagListContainer"
}
const _hoisted_36 = { class: "text-[#344054] text-[14px]" }
const _hoisted_37 = { class: "flex flex-col gap-[6px] w-[512px] md:w-full" }
const _hoisted_38 = { class: "flex gap-[4px] flex-wrap items-center w-full border rounded-[4px] border-gray-300 min-h-[40px] p-[6px]" }
const _hoisted_39 = { class: "scroll-container flex gap-[4px] flex-wrap max-h-[120px] overflow-y-auto" }
const _hoisted_40 = { class: "flex items-center gap-[5px] border rounded-[5px] border-gray-300 px-[5px] py-[2px]" }
const _hoisted_41 = { class: "rounded-md max-h-[300px] overflow-y-auto border border-gray-200 bg-white shadow-lg py-[4px] px-[6px]" }
const _hoisted_42 = ["onClick"]
const _hoisted_43 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_44 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_45 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_46 = { class: "max-w-[864px] text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_47 = { class: "text-black font-medium" }
const _hoisted_48 = { class: "flex flex-col gap-[6px]" }
const _hoisted_49 = { class: "text-[14px] text-[#475467]" }
const _hoisted_50 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_51 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_52 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_53 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_54 = { class: "text-black font-medium" }
const _hoisted_55 = { class: "text-black font-medium break-words" }
const _hoisted_56 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_57 = { class: "text-black font-medium break-words" }
const _hoisted_58 = { class: "flex flex-col gap-[8px]" }
const _hoisted_59 = { class: "text-[14px] text-[#475467]" }
const _hoisted_60 = { class: "flex" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_button = _resolveComponent("el-button")
  const _component_Close = _resolveComponent("Close")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" 展示英文名 "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('datasets.datasetName')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('datasets.datasetNameTips')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('datasets.namespaceDatasetName')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_8, _toDisplayString($data.datasetPath), 1 /* TEXT */)
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 更新数据集别名 "),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('datasets.datasetNickName')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('datasets.edit.tips')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_el_input, {
          modelValue: $data.theDatasetNickname,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.theDatasetNickname) = $event)),
          clearable: "",
          size: "large",
          class: "!w-[512px] sm:!w-full"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(_component_el_button, {
          onClick: $options.updateNickname,
          class: "w-[100px]"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('all.update')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 更新数据集简介 "),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('datasets.datasetDesc')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('datasets.edit.tips2')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_el_input, {
          modelValue: $data.theDatasetDesc,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.theDatasetDesc) = $event)),
          clearable: "",
          size: "large",
          type: "textarea",
          class: "!w-[512px] sm:!w-full"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(_component_el_button, {
          onClick: $options.updateDatasetDesc,
          class: "w-[100px]"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('all.update')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 数据集标签 "),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('datasets.datasetTag')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('datasets.edit.tips3')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.$t('datasets.datasetTag')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selectedTags, (tag) => {
                return (_openBlock(), _createElementBlock("span", _hoisted_28, [
                  _createTextVNode(_toDisplayString(this.$i18n.locale === 'zh'
                    ? tag.zh_name || tag.show_name || tag.name
                    : tag.name) + " ", 1 /* TEXT */),
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Close, {
                        onClick: $event => ($options.removeTag(tag.name))
                      }, null, 8 /* PROPS */, ["onClick"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]))
              }), 256 /* UNKEYED_FRAGMENT */))
            ]),
            _withDirectives(_createElementVNode("input", {
              class: "w-full max-h-[36px] outline-none",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.tagInput) = $event)),
              onInput: _cache[3] || (_cache[3] = (...args) => ($options.showTagList && $options.showTagList(...args)))
            }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
              [_vModelText, $data.tagInput]
            ])
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_29, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.theTagList, (tag) => {
              return (_openBlock(), _createElementBlock("p", {
                onClick: $event => ($options.selectTag(tag)),
                class: "flex gap-[8px] items-center cursor-pointer p-[10px]"
              }, _toDisplayString(this.$i18n.locale === 'zh' ? tag.zh_name || tag.name : tag.name), 9 /* TEXT, PROPS */, _hoisted_30))
            }), 256 /* UNKEYED_FRAGMENT */))
          ], 512 /* NEED_PATCH */), [
            [_vShow, $data.shouldShowTagList]
          ]),
          _createVNode(_component_el_button, {
            onClick: $options.updateTags,
            class: "w-[100px]"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('all.update')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"])
        ])
      ], 512 /* NEED_PATCH */)
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 行业标签 "),
    _createElementVNode("div", _hoisted_31, [
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t('datasets.datasetIndustryTag')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.$t('datasets.edit.tips4')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_35, [
        _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.$t('datasets.datasetIndustryTag')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("div", _hoisted_38, [
            _createElementVNode("div", _hoisted_39, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selectedIndustryTags, (tag) => {
                return (_openBlock(), _createElementBlock("span", _hoisted_40, [
                  _createTextVNode(_toDisplayString(this.$i18n.locale === 'zh'
                    ? tag.zh_name || tag.show_name || tag.name
                    : tag.name) + " ", 1 /* TEXT */),
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Close, {
                        onClick: $event => ($options.removeIndustryTag(tag.name))
                      }, null, 8 /* PROPS */, ["onClick"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]))
              }), 256 /* UNKEYED_FRAGMENT */))
            ]),
            _withDirectives(_createElementVNode("input", {
              class: "w-full max-h-[36px] outline-none",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.industryTagInput) = $event)),
              onInput: _cache[5] || (_cache[5] = (...args) => ($options.showIndustryTagList && $options.showIndustryTagList(...args)))
            }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
              [_vModelText, $data.industryTagInput]
            ])
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_41, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.theIndustryTagsList, (tag) => {
              return (_openBlock(), _createElementBlock("p", {
                onClick: $event => ($options.selectIndustryTag(tag)),
                class: "flex gap-[8px] items-center cursor-pointer p-[10px]"
              }, _toDisplayString(this.$i18n.locale === 'zh'
                  ? tag.show_name || tag.name
                  : tag.name), 9 /* TEXT, PROPS */, _hoisted_42))
            }), 256 /* UNKEYED_FRAGMENT */))
          ], 512 /* NEED_PATCH */), [
            [_vShow, $data.shouldShowIndustryTagList]
          ]),
          _createVNode(_component_el_button, {
            onClick: $options.updateIndustryTags,
            class: "w-[100px]"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('all.update')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"])
        ])
      ], 512 /* NEED_PATCH */)
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 修改可见性 "),
    _createElementVNode("div", _hoisted_43, [
      _createElementVNode("div", _hoisted_44, [
        _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.$t('datasets.edit.changeVisibility')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_46, [
          _createTextVNode(_toDisplayString(_ctx.$t('datasets.edit.statusText')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_47, "【" + _toDisplayString(_ctx.isPrivate ? this.$t('all.private') : this.$t('all.public')) + "】", 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('datasets.edit.status')) + _toDisplayString(_ctx.isPrivate
              ? this.$t('datasets.edit.privateVis')
              : this.$t('datasets.edit.publicVis')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_48, [
        _createElementVNode("p", _hoisted_49, _toDisplayString(_ctx.$t('datasets.edit.datasetVisibility')), 1 /* TEXT */),
        _createVNode(_component_el_select, {
          modelValue: $options.visibilityName,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($options.visibilityName) = $event)),
          onChange: $options.changeVisibility,
          placeholder: "Select",
          size: "large",
          class: "!w-[512px] sm:!w-full",
          disabled: _ctx.isPrivate
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.options, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.value,
                label: item.label,
                value: item.value
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "onChange", "disabled"]),
        (_ctx.isPrivate)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              onClick: $options.applyForPublic,
              type: "primary",
              size: "large"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" 申请公开 ")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 数据集删除 "),
    _createElementVNode("div", _hoisted_50, [
      _createElementVNode("div", _hoisted_51, [
        _createElementVNode("div", _hoisted_52, _toDisplayString(_ctx.$t('datasets.edit.delDataset')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_53, [
          _createTextVNode(_toDisplayString(_ctx.$t('datasets.edit.delTips')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_54, _toDisplayString(_ctx.$t('datasets.edit.canNot')), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('datasets.edit.delTips2')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_55, _toDisplayString($props.path), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('datasets.edit.delTips3')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_56, [
          _createTextVNode(_toDisplayString(_ctx.$t('datasets.edit.enterPls')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_57, _toDisplayString($props.path), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('datasets.edit.sureDel')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_58, [
        _createElementVNode("p", _hoisted_59, _toDisplayString(_ctx.$t('datasets.datasetName')), 1 /* TEXT */),
        _createVNode(_component_el_input, {
          modelValue: $data.delDesc,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($data.delDesc) = $event)),
          clearable: "",
          size: "large",
          class: "!w-[512px] sm:!w-full"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("div", _hoisted_60, [
          _createElementVNode("div", {
            id: "confirmDelete",
            onClick: _cache[8] || (_cache[8] = (...args) => ($options.clickDelete && $options.clickDelete(...args))),
            class: _normalizeClass(["text-[#98A2B3] py-[8px] px-[12px] text-[14px] leading-[20px] rounded-[8px]", 
              $data.delDesc === $data.datasetPath
                ? 'bg-[#D92D20] text-[#FFFFFF] cursor-pointer active:shadow-box active:space-y-0 active:space-x-0 active:ring-4 active:ring-red-400 active:ring-opacity-25 active:bg-[#D92D20] hover:text-white'
                : 'bg-[#F2F4F7]'
            ]),
            onMouseover: _cache[9] || (_cache[9] = (...args) => ($options.handleMouseOver && $options.handleMouseOver(...args))),
            onMouseleave: _cache[10] || (_cache[10] = (...args) => ($options.handleMouseLeave && $options.handleMouseLeave(...args)))
          }, _toDisplayString(_ctx.$t('datasets.edit.confirmDel')), 35 /* TEXT, CLASS, NEED_HYDRATION */)
        ])
      ])
    ])
  ]))
}