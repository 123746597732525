export const admin = {
  dashboard: {
    title: 'Admin Dashboard',
    intro: 'This is the admin pannel, admin users can do the system settings here.'
  },
  emailSending: {
    title: 'Admin Email Sending',
    mailGroupSelect: 'Mail Group Select',
    mailTemplateInput: 'Mail Template Input',
    send: 'Send',
    allUsers: 'All Users',
    groupMissing: 'Please select a group',
    templateMissing: 'Please provide a template',
    sendSuccess: 'Email send out success'
  },
  syncSetting: {
    title: 'Sync Setting',
    concurrent: 'Concurrent Task',
    bandwidth: 'Bandwidth Limit (MB)',
    submit: 'Submit',
    update: 'Update',
    queues: 'Sync Queue',
    defaultTokenAlert: 'Current using the default multi-sync token, only support viewing and not support syncing. If you need to sync, please register on the CAICT community.'
  }
}