<template>
  <span>由 <span @click="jumpToOwnerUrl" class="text-[#FF5005]">{{ props.supplier }}</span> 提供</span>
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1"
      height="8"
      viewBox="0 0 1 8"
      fill="none"
    >
      <path d="M0.5 0V8" stroke="#DCDFE6" />
    </svg>
  </span>
  <span>更新时间：{{ props.updated_at && props.updated_at.substring(0, 10) }}</span>
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1"
      height="8"
      viewBox="0 0 1 8"
      fill="none"
    >
      <path d="M0.5 0V8" stroke="#DCDFE6" />
    </svg>
  </span>
  <span>下载量：{{ props.downloads }}</span>
</template>

<script setup>
  const props = defineProps({
    downloads: Number,
    updated_at: String,
    supplier: String,
    ownerUrl: String
  });

  const jumpToOwnerUrl = () => {
    if (props.ownerUrl) {
      window.open(props.ownerUrl);
    }
  };

</script>