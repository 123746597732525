import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d04614a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col gap-[32px] p-[16px] pt-[24px]" }
const _hoisted_2 = { class: "font-semibold text-[20px] leading-[28px] pt-[16px]" }
const _hoisted_3 = { class: "el-upload__text" }
const _hoisted_4 = { class: "text-[#223B99] font-[500]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_upload_filled = _resolveComponent("upload-filled")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_upload = _resolveComponent("el-upload")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('organization.orgInfo')), 1 /* TEXT */),
    _createVNode(_component_el_form, {
      ref: "dataFormRef",
      model: $setup.organization,
      rules: $setup.rules,
      "label-position": "top",
      class: "mt-[48px] text-left",
      style: {"--el-border-radius-base":"8px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('organization.orgNameSpace'),
          prop: "name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              disabled: "",
              modelValue: $setup.organization.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.organization.name) = $event)),
              placeholder: _ctx.$t('rule.nameRule')
            }, {
              prepend: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.getDomain()), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "placeholder"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('organization.orgNickName'),
          prop: "nickname"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $setup.organization.nickname,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.organization.nickname) = $event)),
              placeholder: _ctx.$t('all.pleaseInput', {value: _ctx.$t('organization.orgNickName')})
            }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('organization.orgType'),
          prop: "org_type"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: $setup.organization.org_type,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.organization.org_type) = $event)),
              placeholder: _ctx.$t('all.pleaseSelect', {value: _ctx.$t('organization.orgType')}),
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.org_types, (value) => {
                  return _createVNode(_component_el_option, {
                    key: value,
                    label: value,
                    value: value
                  }, null, 8 /* PROPS */, ["label", "value"])
                }), 64 /* STABLE_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "placeholder"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('organization.orgHomepage'),
          prop: "homepage"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $setup.organization.homepage,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.organization.homepage) = $event)),
              placeholder: "example.com"
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: $setup.selectedProtocol,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.selectedProtocol) = $event)),
                  style: {"width":"100px","border":"none"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "https://",
                      value: "https://"
                    }),
                    _createVNode(_component_el_option, {
                      label: "http://",
                      value: "http://"
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('organization.orgAvatar'),
          prop: "logo_image"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_upload, {
              class: _normalizeClass(`w-full ${!$setup.organization.logo ? 'h-[229px]' : 'hide'}`),
              limit: 1,
              "file-list": $setup.logo_images,
              "onUpdate:fileList": _cache[5] || (_cache[5] = $event => (($setup.logo_images) = $event)),
              drag: "",
              "list-type": "picture",
              headers: { 'X-CSRF-TOKEN': $setup.csrf_token },
              accept: "image/png, image/jpeg, image/gif, image/svg+xml",
              data: {
            namespace: 'org-logo',
            file_max_size: 1024*1024
          },
              action: "/internal_api/upload",
              "on-progress": $setup.handleUploadProgress,
              "on-remove": $setup.handleRemoveImage,
              "on-success": $setup.handleUploadSuccess,
              "on-error": $setup.handleUploadError
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("p", null, [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('components.upload.click_to_upload')), 1 /* TEXT */),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('components.upload.or_drag_file')), 1 /* TEXT */)
                    ]),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('components.upload.upload_type', {accept: 'SVG, PNG, JPG, GIF'})), 1 /* TEXT */)
                  ]),
                  _createVNode(_component_el_icon, { class: "el-icon--upload" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_upload_filled)
                    ]),
                    _: 1 /* STABLE */
                  })
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["class", "file-list", "headers"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              class: "!text-center !h-[48px] !text-[16px] btn btn-orange",
              onClick: $setup.handleSubmit
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('all.saveSetting')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["model", "rules"])
  ]))
}