export const codes = {
  title: "Codes",
  tips:"Creating a code repository to host your code",
  placeholder: "Search Codes",
  downloadCode: "Download Code",
  codeName: "Code name",
  codeNameTips: "Applied to the code path, cannot be changed after creation",
  namespaceCodeName: "Code name",
  codeNickName: "Code alias",
  codeDesc: "Code description",
  codesUsedToTrain: 'Codes used to train',
  newCode: {
    title: "New Code Repository",
    titleDesc: "Repository contains all code files and revision history",
    owner: "Owner",
    codeName: "Code Repository Name",
    codeNickName: "Code Repository Nickname",
    codeDesc: "Code Repository Description",
    public: "public",
    publicDesc: "Anyone on the Internet can see this warehouse. Only you (the individual) or a member of your organization can submit.",
    private: "private",
    privateDesc: "Only you (the individual) or members of your organization can see and submit to this warehouse.",
    tips: "After creating your code repository, you can upload your files using a web page or Git.",
    createCode: "Create",
    createSuccess: "Code repository created successfully",
  },
  edit: {
    tips2: "An introduction to code, optional",
    changeVisibility: "Modify code visibility",
    statusText: "The current code is currently",
    status: "Status.",
    codeVisibility: "Code visibility",
    delCode: "Delete code",
    delTips: "This action",
    canNot: "cannot",
    delTips2: "be undone and will permanently delete the code repository",
    delTips3: "and all its files.",
    enterPls: "Please type",
    sureDel: "to confirm deletion",
    privateVis: "Visible only to the creator or member of the organization",
    publicVis: "Anyone can see this code",
    confirmDel: "I understand and confirm deletion",
    privateInfo: ", only the creator or organization administrator can submit",
    publicInfo: "Anyone on the Internet can see this code.",
    needCodeName: "Please provide code alias first",
    needCodelDesc: "Please provide code introduction first",
    codeName: "Code name",
    codeNickName: "Code nickname",
    codeDesc: "Code description",
    public: "Public",
    publicDesc: "Anyone on the internet can see this repository. Only you (individual) or members of your organization can commit.",
    private: "Private",
    privateDesc: "Only you (individual) or members of your organization can view and commit to this repository.",
    tips: "After creating the code, you can use the web or Git to upload your files.",
    createCode: "Create code",
    createSuccess: "Code Repository created successfully",
    tip: "Applies to the code base path and cannot be changed after creation",
    license: "License",
    createFail: "Code repository creation failed",
    codeEnName: "Code repository name",
  },
}
