export const dailyPaper = {
  "paper": "论文中心",
  "eachpaper": "每期精选论文",
  "arxiv": "来自 arxiv 最新的新闻、技术和资源",
  "newpaper": "发布新的社区论文",
  "placeholder": "按 arxiv id 或标题搜索",


  "goodpaper": "贡献优质社区论文",
  "recommendUser": "将您发现的优质论文推荐给 CAICT 社区用户",
  "title": "标题",
  "paperTitle": "推荐论文标题",
  "img": "论文图片",
  "click": "点击上传，",
  "drug": "或将文件拖到此处",
  "type": "SVG, PNG, JPG 或 GIF",
  "link": "论文链接",
  "paperLink": "推荐论文链接",
  "publishTime": "原文发布时间",
  "date": "选择日期",
  "pdfLink": "论文 PDF 链接",
  "recommendPdfLink": "推荐论文 PDF 链接",
  "paperRemark": "论文摘要",
  "videoLin": "论文关联视频链接",
  "paperModel": "论文关联的模型 选填",
  "paperDataset": "论文关联的数据集 选填",
  "linkDesc": "输入关联模型的链接，通过换行区分不同模型链接",
  "submit": "发布论文推荐",

  "errorTitle": "请输入标题名称",
  "errorImg": "请上传论文图片",
  "errorpaperLink": "请输入论文链接",
  "errorpaperLink-": "请输入正确的论文链接",
  "errorPublish": "请选择论文发布时间",
  "errorPdf": "请输入论文 PDF 链接",
  "errorPdf-": "请输入正确的论文 PDF 链接",
  "errorRecommendation": "请输入论文推荐语",
  "errorRemark": "请输入论文摘要",
  "createSuccess": "创建成功",
  "createFail": "创建失败",


  "back": "返回论文列表",
  "recommendation": "推荐语",
  "recommender": "推荐者",
  "remark": "摘要",
  "arXiv": "查看 arXiv 页面",
  "pdf": "查看 PDF",
  "discuss": "讨论",
  "reply": "回复",
  "create": "创建",
  "cancel": "取消",
  "model": "关联模型",
  "dataset": "关联数据集",
  "video": "关联视频",
  "warn": "内容不能为空",
  "success": "添加评论成功"
}
