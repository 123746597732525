<template>
  <div class="bg-white">
    <div class="m-auto w-[583px] md:w-full md:px-[20px]">
      <div class="py-[96px] text-center">
        <div class="text-[20px] font-[500] leading-[30px]">
          数字化需求
        </div>
        <div class="flex gap-[8px] w-full my-[16px]">
          <div :class="`flex justify-center w-full cursor-pointer py-[8px] text-[16px] bg-round ${step === 1 ? 'issue-start-active-bar' : 'issue-start-inactive-bar'}`" @click="prevStep">
            基本信息
          </div> 
          <div :class="`flex justify-center w-full cursor-pointer py-[8px] text-[16px] bg-round ${step === 1 ? 'issue-end-inactive-bar' : 'issue-end-active-bar'}`" @click="nextStep">
            预算与要求
          </div> 
        </div>
        <el-form
          ref="dataForm"
          :model="dataForm"
          :rules="rules"
          label-position="top"
          class="text-left">
          <template v-if="step === 1">
            <el-form-item label="项目名称" prop="project_name">
              <el-input v-model="dataForm.project_name" placeholder="请输入项目名称"></el-input>
            </el-form-item>
            <el-form-item label="组织名称" prop="org_name">
              <el-input v-model="dataForm.org_name" placeholder="请输入组织名称"></el-input>
            </el-form-item>
            <el-form-item label="交付日期" prop="delivery_at">
              <el-date-picker
                v-model="dataForm.delivery_at"
                type="date"
                style="width: 100%;"
                placeholder="选择交付日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="所属行业" prop="industry_list">
              <el-select
                v-model="dataForm.industry_list"
                multiple
                filterable
                :reserve-keyword="false"
                default-first-option
                :placeholder="$t('all.pleaseSelect', {value: '行业'})"
                style="width: 100%;"
              >
                <el-option
                  v-for="value in this.industries"
                  :key="value"
                  :label="value"
                  :value="value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="联系人" prop="contact_name">
              <el-input v-model="dataForm.contact_name" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="contact_phone">
              <el-input v-model="dataForm.contact_phone" placeholder="请输入联系方式"></el-input>
            </el-form-item>
            <el-form-item label="项目概述" prop="description">
              <el-input :rows="5" type="textarea" v-model="dataForm.description"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="w-full text-center !h-[48px] !text-[16px] !text-white !bg-[#FF5005] btn-orange !rounded-[8px] !border-[1px] !border-[#FF5005]" @click="nextStep">下一步</el-button>
            </el-form-item>
          </template>
          <template v-if="step === 2">
            <el-form-item label="预算" class="append-input" prop="budget">
              <el-input v-model="dataForm.budget" placeholder="请输入预算">
                <template #append>万</template>
              </el-input>
            </el-form-item>
            <el-form-item label="需求类型" prop="demand_list">
              <el-select
                v-model="dataForm.demand_list"
                multiple
                filterable
                :reserve-keyword="false"
                default-first-option
                :placeholder="$t('all.pleaseSelect', {value: '需求类型'})"
                style="width: 100%;"
              >
                <el-option
                  v-for="value in this.demands"
                  :key="value"
                  :label="value"
                  :value="value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="功能需求" prop="feature_requirement">
              <el-input :rows="5" type="textarea" v-model="dataForm.feature_requirement"></el-input>
            </el-form-item>
            <el-form-item label="设计需求" prop="design_requirement">
              <el-input :rows="5" type="textarea" v-model="dataForm.design_requirement"></el-input>
            </el-form-item>
            <el-form-item label="安全和隐私要求" prop="private_requirement">
              <el-input :rows="5" type="textarea" v-model="dataForm.private_requirement"></el-input>
            </el-form-item>
            <el-form-item label="其他需求或备注" prop="other_requirement">
              <el-input :rows="5" type="textarea" v-model="dataForm.other_requirement"></el-input>
            </el-form-item>
            <el-form-item>
              <div class="w-full flex gap-[4px]">
                <el-button class="w-full text-center !h-[48px] !w-[116px] !text-[#344054] !text-[16px] bg-[#FF5005] !rounded-[8px] !border-[1px] " @click="prevStep">上一步</el-button>
                <el-button class="w-full text-center !h-[48px] !text-[16px] !text-white !bg-[#FF5005] btn-orange !rounded-[8px] !border-[1px] !border-[#FF5005]" @click="handleSubmit">提交</el-button>
              </div>
            </el-form-item>
          </template>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  import csrfFetch from "../../../../packs/csrfFetch.js"

  export default {
    props: {
      industries: {
        type: Array,
        default: () => []
      },
      demands: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        step: 1,
        dataForm: {},
        rules: {
          org_name: [{ required: true, message: '请输入组织名称', trigger: 'blur' }],
          project_name: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
          delivery_at: [{ required: true, message: '请选择交付日期', trigger: 'blur' }],
          industry_list: [{ required: true, message: '请选择所属行业', trigger: 'blur' }],
          demand_list: [{ required: true, message: '请选择需求类型', trigger: 'blur' }],
          description: [{ required: true, message: '请输入文章摘要', trigger: 'blur' }],
          budget: [{ required: true, message: '请输入预算', trigger: 'blur' }],
        }
      }
    },
    methods: {
      nextStep () {
        this.$refs['dataForm'].validate(async (valid) => {
          if (valid) {
            this.step = 2
          } else {
            return false
          }
        })
      },
      prevStep () {
        this.step = 1
      },
      handleSubmit() {
        this.$refs['dataForm'].validate(async (valid) => {
          if (valid) {
            await this.createIssue()
          } else {
            return false
          }
        })
      },
      async createIssue() {
        const params = Object.assign({}, this.dataForm)
        const options = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params)
        }
        const uploadEndpoint = '/digital_issues'
        const response = await csrfFetch(uploadEndpoint, options)
        if (response.ok) {
          this.$message({
            message: '数字化需求提交成功。',
            type: 'success'
          });
          setTimeout(() => {
            response.json().then(res => {
              window.location.href = `/issues/${res.id}`
            })
          }, 500)
        } else {
          response.json()
            .then(res => { 
              this.$message({
                message: `创建失败: ${res.message}`,
                type: 'error'
              });
            })
        }
      }
    }
  }
</script>
<style scoped>
  .el-form-item {
    margin-bottom: 24px;
  }
  .el-form-item__label {
    margin-bottom: 6px;
  }
  :deep(.el-input__wrapper) {
    border-radius: 8px;
  }
  :deep(.append-input .el-input__wrapper) {
    border-radius: 8px 0 0 8px;
  }
  :deep(.el-input-group__append, .el-input-group__prepend) {
    background: transparent;
    color: #344054;
  }
  :deep(.el-textarea__inner) {
    border-radius: 8px;
  }
  :deep(.el-upload--picture-card) {
    border: 0px;
  }
  :deep(.hide .el-upload.el-upload--picture-card){
    display: none;
  }
</style>