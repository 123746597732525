export const colorMap = (type) => {
  const map = {
    'task': '#FFA801',
    'framework': '#2ECC71',
    'language': '#3498DB',
    'license': '#F56C6C'
  }
  return map[type] || '#FF3F34'
}

export const stringMod = (string, divisor) => {
  let total = 0;
  for (let i = 0; i < string.length; i++) {
      total += string.charCodeAt(i);
  }
  return total % divisor;
}