import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fba002ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border-b border-[#F2F2F2] px-[16px] py-[12px] text-[#000000E0]" }
const _hoisted_2 = { class: "flex w-[328px] flex-col text-[12px]" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "text-[#00000099] font-[500] text-[12px] py-[16px]" }
const _hoisted_5 = { class: "flex gap-[12px] flex-wrap" }
const _hoisted_6 = { class: "flex gap-[8px] flex-wrap" }
const _hoisted_7 = { class: "flex gap-[8px] flex-wrap" }
const _hoisted_8 = ["data-tag_name"]
const _hoisted_9 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "12",
  viewBox: "0 0 12 12",
  fill: "none"
}
const _hoisted_10 = ["stroke"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_drawer = _resolveComponent("el-drawer")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        "data-type": "Task",
        class: _normalizeClass(`${
        $setup.activeNavItem === 'Task' ? ' active-btn' : ''
      } mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`),
        onClick: $setup.changeActiveItem
      }, " 任务 ", 2 /* CLASS */),
      _createCommentVNode(" <span\n      data-type=\"Framework\"\n      v-show=\"type == 'model'\"\n      :class=\"`${\n        activeNavItem === 'Framework' ? ' active-btn' : ''\n      } mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`\"\n      @click=\"changeActiveItem\"\n    >\n      框架\n    </span> "),
      _createElementVNode("span", {
        "data-type": "License",
        class: _normalizeClass(`${
        $setup.activeNavItem === 'License' ? ' active-btn' : ''
      } mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`),
        onClick: $setup.changeActiveItem
      }, " 许可证 ", 2 /* CLASS */)
    ]),
    _createVNode(_component_el_drawer, {
      modelValue: $setup.drawer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.drawer) = $event)),
      direction: "btt",
      "with-header": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createCommentVNode(" task tags "),
            _withDirectives(_createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.theTaskTags, (value, key) => {
                  return (_openBlock(), _createElementBlock("div", null, [
                    _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t(`tags.${key}`)), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, (tag) => {
                        return (_openBlock(), _createBlock($setup["TagItem"], {
                          tag: tag,
                          active: $setup.activeTaskTag === tag.name,
                          onHandleTagClick: $setup.setActiveTaskTag
                        }, null, 8 /* PROPS */, ["tag", "active"]))
                      }), 256 /* UNKEYED_FRAGMENT */))
                    ])
                  ]))
                }), 256 /* UNKEYED_FRAGMENT */))
              ])
            ], 512 /* NEED_PATCH */), [
              [_vShow, $setup.showTask]
            ]),
            _createCommentVNode(" framework tags "),
            _withDirectives(_createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_6, [
                _withDirectives(_createVNode($setup["PyTorch"], {
                  onSetActiveFrameworkTag: $setup.setActiveFrameworkTag,
                  activeTag: $setup.activeFrameworkTag
                }, null, 8 /* PROPS */, ["activeTag"]), [
                  [_vShow, $setup.frameworkTagExist('PyTorch')]
                ]),
                _withDirectives(_createVNode($setup["TensorFlow"], {
                  onSetActiveFrameworkTag: $setup.setActiveFrameworkTag,
                  activeTag: $setup.activeFrameworkTag
                }, null, 8 /* PROPS */, ["activeTag"]), [
                  [_vShow, $setup.frameworkTagExist('TensorFlow')]
                ]),
                _withDirectives(_createVNode($setup["Safetensors"], {
                  onSetActiveFrameworkTag: $setup.setActiveFrameworkTag,
                  activeTag: $setup.activeFrameworkTag
                }, null, 8 /* PROPS */, ["activeTag"]), [
                  [_vShow, $setup.frameworkTagExist('Safetensors')]
                ]),
                _withDirectives(_createVNode($setup["ONNX"], {
                  onSetActiveFrameworkTag: $setup.setActiveFrameworkTag,
                  activeTag: $setup.activeFrameworkTag
                }, null, 8 /* PROPS */, ["activeTag"]), [
                  [_vShow, $setup.frameworkTagExist('ONNX')]
                ]),
                _withDirectives(_createVNode($setup["JAX"], {
                  onSetActiveFrameworkTag: $setup.setActiveFrameworkTag,
                  activeTag: $setup.activeFrameworkTag
                }, null, 8 /* PROPS */, ["activeTag"]), [
                  [_vShow, $setup.frameworkTagExist('JAX')]
                ]),
                _withDirectives(_createVNode($setup["PaddlePaddle"], {
                  onSetActiveFrameworkTag: $setup.setActiveFrameworkTag,
                  activeTag: $setup.activeFrameworkTag
                }, null, 8 /* PROPS */, ["activeTag"]), [
                  [_vShow, $setup.frameworkTagExist('PaddlePaddle')]
                ]),
                _withDirectives(_createVNode($setup["Joblib"], {
                  onSetActiveFrameworkTag: $setup.setActiveFrameworkTag,
                  activeTag: $setup.activeFrameworkTag
                }, null, 8 /* PROPS */, ["activeTag"]), [
                  [_vShow, $setup.frameworkTagExist('Joblib')]
                ]),
                _withDirectives(_createVNode($setup["GGUF"], {
                  onSetActiveFrameworkTag: $setup.setActiveFrameworkTag,
                  activeTag: $setup.activeFrameworkTag
                }, null, 8 /* PROPS */, ["activeTag"]), [
                  [_vShow, $setup.frameworkTagExist('GGUF')]
                ])
              ])
            ], 512 /* NEED_PATCH */), [
              [_vShow, $setup.showFramework]
            ]),
            _withDirectives(_createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.theLicenseTags, (licenseTag) => {
                  return (_openBlock(), _createElementBlock("span", {
                    class: "text-[14px] text-[#303133] px-[8px] py-[3px] rounded-[6px] cursor-pointer flex items-center gap-[4px] border border-gray-200",
                    "data-tag_name": licenseTag.name,
                    style: _normalizeStyle($setup.setLicenseTagColor(licenseTag.name)),
                    onClick: $setup.setActiveLicenseTag
                  }, [
                    (_openBlock(), _createElementBlock("svg", _hoisted_9, [
                      _createElementVNode("path", {
                        d: "M6 2.99995V11M6 2.99995H4.28328C4.17763 2.99995 4.12481 2.99995 4.07307 3.00719C4.02712 3.01362 3.98186 3.02431 3.93789 3.0391C3.88837 3.05576 3.84112 3.07939 3.74663 3.12664L3 3.49995M6 2.99995H7.71672C7.82237 2.99995 7.87519 2.99995 7.92693 3.00719C7.97288 3.01362 8.01814 3.02431 8.06211 3.0391C8.11163 3.05576 8.15888 3.07939 8.25337 3.12664L9 3.49995M6 2.99995V1M6 11H8.5M6 11H3.5M1 3.99995H1.71672C1.82237 3.99995 1.87519 3.99995 1.92693 3.9927C1.97288 3.98627 2.01814 3.97559 2.06211 3.96079C2.11163 3.94413 2.15888 3.92051 2.25337 3.87326L3 3.49995M11 3.99995H10.2833C10.1776 3.99995 10.1248 3.99995 10.0731 3.9927C10.0271 3.98627 9.98186 3.97559 9.93789 3.96079C9.88837 3.94413 9.84112 3.92051 9.74663 3.87326L9 3.49995M3 3.49995L1.93102 7.06321C1.80356 7.48809 1.73982 7.70054 1.76796 7.87165C1.80739 8.11139 1.9605 8.31718 2.1788 8.42383C2.33461 8.49995 2.55641 8.49995 3 8.49995V8.49995C3.44359 8.49995 3.66539 8.49995 3.8212 8.42383C4.0395 8.31718 4.19261 8.11139 4.23204 7.87165C4.26018 7.70054 4.19644 7.48809 4.06898 7.06321L3 3.49995ZM9 3.49995L7.93102 7.06321C7.80356 7.48809 7.73982 7.70054 7.76796 7.87165C7.80739 8.11139 7.9605 8.31718 8.1788 8.42383C8.33461 8.49995 8.55641 8.49995 9 8.49995V8.49995V8.49995C9.44359 8.49995 9.66539 8.49995 9.8212 8.42383C10.0395 8.31718 10.1926 8.11139 10.232 7.87165C10.2602 7.70054 10.1964 7.48809 10.069 7.06321L9 3.49995Z",
                        stroke: $setup.activeLicenseTag === licenseTag.name ? 'white' : '#606266',
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }, null, 8 /* PROPS */, _hoisted_10)
                    ])),
                    _createTextVNode(" " + _toDisplayString(licenseTag.show_name || licenseTag.name), 1 /* TEXT */)
                  ], 12 /* STYLE, PROPS */, _hoisted_8))
                }), 256 /* UNKEYED_FRAGMENT */))
              ])
            ], 512 /* NEED_PATCH */), [
              [_vShow, $setup.showLicense]
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ], 64 /* STABLE_FRAGMENT */))
}