import request from './request'

/** 用户 开始 */
// 查看用户
export function userDetail(loginIdentity) {
  return request({
    url: `/internal_api/users/${loginIdentity}`,
    method: 'get',
  })
}

// 更新用户
export function userUpdate(loginIdentity, data) {
  return request({
    url: `/internal_api/users/${loginIdentity}`,
    method: 'put',
    data: data
  })
}
/** 用户 结束 */
