<template>
  <a :href="detailLink"
     class="basis-1/2 md:basis-full max-w-[532px] md:max-w-full flex flex-col xty-model-card md:bg-white bg-unset"
  >
    <div class="flex items-center mb-[5px] w-[399px] lg:w-[370px] mlg:w-full gap-[8px]">
      <div :class="`${repoType}-path`"
           class="text-sm text-[#303133] font-medium text-ellipsis overflow-hidden whitespace-nowrap"
      >
        {{ getComputed.path }}
      </div>
      <div class="flex-shrink-0" v-if="repo.tags && repo.tags.length > 0">
        <span :class="`text-[12px] px-[8px] py-[3px] flex gap-[4px] font-[400] rounded-[4px] bg-[${colorMap(displayTag(repo.tags).category)}30] text-[${colorMap(displayTag(repo.tags).category)}]`">{{ displayTag(repo.tags).name }}</span>
      </div>
      <div class="px-[8px] px-[3px] border border-[#DCDFE6] rounded-[4px] text-[12px] text-[#303133] font-[400] leading-[18px]" v-if="repo.private">
        私有
      </div>
    </div>

    <p v-if="getComputed.showDescription"
      class="w-[390px] lg:w-[370px] mlg:w-full h-[35px] leading-[18px] mb-[5px] text-[#909399] text-xs overflow-hidden text-ellipsis line-clamp-2 text-left">
      {{ repo.description }}
    </p>

    <div class="flex flex-nowrap overflow-hidden text-ellipsis items-center gap-[8px] text-xs text-[#909399]">
      <!-- <span v-if="getComputed.taskTag"
            class="max-w-[80px] xl:max-w-full overflow-hidden text-ellipsis whitespace-nowrap"
      >
            {{ getComputed.taskTag }}
      </span>

      <span v-if="getComputed.taskTag">
        <SvgIcon name="vertical_divider" />
      </span> -->

      <short-info :supplier="repo.path.split('/')[0]" :updated_at="repo.updated_at" :downloads="repo.downloads" />
      <!-- <div class="overflow-hidden text-ellipsis whitespace-nowrap">
        {{$t('all.lastTime')}}：{{ repo.updated_at.substring(0, 10) }}
      </div>

      <div class="overflow-hidden text-ellipsis whitespace-nowrap">
        {{$t('all.lastTime')}}：{{ repo.updated_at.substring(0, 10) }}
      </div>

      <span> <SvgIcon name="vertical_divider" /> </span>

      <span class="whitespace-nowrap">{{ getComputed.visibility }}</span>

      <span> <SvgIcon name="vertical_divider" /> </span>

      <span class="whitespace-nowrap">{{$t('all.downloadCount')}}：{{ repo.downloads }}</span> -->
    </div>
  </a>
</template>

<script setup>
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'
  import ShortInfo from "../shared/ShortInfo.vue";
  import TagItem from '../tags/TagItem.vue'
  import { colorMap } from "../helpers/utils";


  const props = defineProps({
    repo: Object,
    repoType: String,
    cardType: {
      type: String,
      default: 'index'
    }
  })

  const { t, locale } = useI18n()

  const detailLink = computed(() => {
    switch (props.repoType) {
      case 'model':
        return `/models/${props.repo.path}`
      case 'dataset':
        return `/datasets/${props.repo.path}`
      case 'space':
        return `/spaces/${props.repo.path}`
      case 'code':
        return `/codes/${props.repo.path}`
      default:
        return ''
    }
  })

  const getComputed = computed(() => {
    const nickName = props.repo.nickname !== undefined ? props.repo.nickname : ''
    const repoName = props.repo.name || props.repo.path.split('/')[1]
    const displayName = nickName || repoName
    const path = props.repo.path.split('/')[0] + '/' + displayName

    const visibility = props.repo.private ? t('all.private')  : t('all.public')
    const showDescription = props.cardType === 'index' || !!props.repo.description?.trim()

    let taskTag = (props.repo.tags || []).find(tag => tag.category === "task")
    if (locale.value === 'en') {
      taskTag = taskTag? taskTag["name"].replace(/-/g, ' ') : null
    }
    else {
      taskTag = taskTag? taskTag["show_name"] : null
    }

    return { path, visibility, taskTag, showDescription }
  })

  const displayTag = (tags) => {
    if (tags?.length > 0) {
      tag = tags.find((tag) => tag.category === "task")
    }
    if (tags?.length > 0 && (tag == undefined || tag == null)) {
      tag = tags[0]
    }
    return tag
  }
</script>

<style scoped>
  .dataset-card:hover .dataset-path {
    color: var(--blue-blue-5001-f-75-cb, #FF5005);
  }

  .model-card:hover .model-path {
    color: var(--theme-dark-red-t-red-500-ad-4-a-3-b, #FF5005);
  }
</style>
