<template>
  <div class="flex md:block max-w-[1440px] m-auto min-h-[calc(100vh-153px)] md:min-h-0 ml-[32px] md:ml-0">
    <div v-if="!mobileScreen" class="flex w-[328px] flex-col bg-gradient-to-l from-[#FAFAFA] to-white border-r border-[#F2F2F2] text-[12px] h-full" >
      <!-- 行业 -->
      <h3 class="text-[#00000099] font-[500] text-[12px] py-[16px]">行业</h3>
      <div class="flex gap-[12px] flex-wrap">
        <template v-for="tag in tags" :key="tag">
          <span
            :class="`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer flex items-center gap-[3px] border border-[#D0D5DD] ${activeTag === tag ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`"
            @click="handleTagClick(tag)"
          >
            {{ tag }}
          </span>
        </template>
      </div>
      <!-- 需求类型 -->
      <h3 class="text-[#00000099] font-[500] text-[12px] py-[16px]">需求类型</h3>
      <div class="flex gap-[12px] flex-wrap">
        <template v-for="issueType in issueTypes" :key="issueType.value">
          <span
            :class="`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer flex items-center gap-[3px] border border-[#D0D5DD] ${activeIssueType === issueType.value ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`"
            @click="handleIssueTypeClick(issueType.value)"
          >
            {{ issueType.label }}
          </span>
        </template>
      </div>
    </div>

    <div v-else class="w-full">
      <div class="border-b border-[#F2F2F2] px-[16px] py-[12px] text-[#000000E0]">
        <span
          :class="`${activeNavItem === 'Industry' ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`"
          @click="this.drawer = true; this.activeNavItem = 'Industry'"
        >
          行业
        </span>
        <span
          :class="`${activeNavItem === 'IssueType' ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`"
          @click="this.drawer = true; this.activeNavItem = 'IssueType'"
        >
          需求类型
        </span>
      </div>

      <el-drawer v-model="drawer" direction="btt" :with-header="false">
        <div class="flex flex-wrap gap-[5px]" v-if="activeNavItem === 'Industry'">
          <template v-for="tag in tags" :key="tag">
            <div
              :class="`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD] ${activeTag === tag ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`"
              @click="handleTagClick(tag)"
            >
              {{ tag }}
            </div>
          </template>
        </div>
        <div class="flex flex-wrap gap-[5px]" v-else-if="activeNavItem === 'IssueType'">
          <template v-for="issueType in issueTypes" :key="issueType.value">
            <div
              :class="`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD] ${activeIssueType === issueType.value ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`"
              @click="handleIssueTypeClick(issueType.value)"
            >
              {{ issueType.label }}
            </div>
          </template>
        </div>
      </el-drawer>
    </div>

    <div class="pr-[20px] pt-[32px] md:px-[20px] md:pt-[0px] w-full">
      <div class="flex xl:flex-col justify-end">
        <div class="xl:mt-[16px] md:w-full mr-[16px] md:mr-0">
          <el-input
            v-model="keywords"
            class="w-[320px] md:w-full"
            size="large"
            placeholder="搜索关键字"
            :prefix-icon="Search"
            @change="fetchIssues"
          />
        </div>
      </div>

      <div class="grid grid-cols-2 md:grid-cols-1 gap-[16px] px-[16px] py-[12px] md:px-[0px]">
        <template v-for="issue in issues" :key="issue.id">
          <a :href="`/issues/${issue.id}`">
            <div class="flex flex-col gap-[10px] border-[#E6E6E6] border-[1px] rounded-[4px] p-[16px] shadow-[0px_0px_0px_0px_rgba(0,0,0,0.05)] cursor-pointer xty-model-card">
              <div class="flex gap-[16px] justify-between text-[18px] font-[500] leading-[28px]">
                <div class="text-[rgba(0, 0, 0, 0.88)] overflow-ellipsis line-clamp-1">{{ issue.project_name }}</div>
                <div class="text-[#FF8035]">
                  <template v-if="issue.budget > 0">
                    <div class="">{{ issue.budget }}万</div>
                  </template>
                  <template v-else>
                    <div class="">面议</div>
                  </template>
                </div>
              </div>

              <div class="flex items-center gap-[8px] text-[#667085] text-[12px] leading-[20px] font-[400]">
                <div class="flex items-center gap-[4px]">
                  <el-icon><View /></el-icon>
                  <div>{{ issue.view_count }}</div>
                </div>
                <div class="">
                  |
                </div>
                <div class="flex items-center gap-[4px]">
                  <el-icon><Clock /></el-icon>
                  <div>截止时间：{{ issue.delivery_at ? dayjs(issue.delivery_at).format("YYYY-MM-DD") : '面议' }}</div>
                </div>
              </div>

              <div class="text-[#475467] text-[14px] leading-[22px] font-[400]">
                <span class="text-[#475467]">企业名称：</span>
                <span class="text-[#667085]">{{ issue.org_name }}</span>
              </div>

              <div class="text-[#475467] text-[14px] leading-[22px] font-[400]">
                <span class="text-[#475467]">产业链：</span>
                <span class="text-[#667085]">{{ issue.industry_list.join('、') }}</span>
              </div>

              <div class="text-[#475467] text-[14px] leading-[22px] font-[400]">
                <span class="text-[#475467]">发布时间：</span>
                <span class="text-[#667085]">{{ dayjs(issue.created_at).format("YYYY-MM-DD") }}</span>
              </div>


              <div class="text-[#475467] text-[14px] leading-[22px] font-[400] overflow-ellipsis line-clamp-2">
                <span class="text-[#475467]">需求介绍：</span>
                <span class="text-[#667085]">{{ issue.description }}</span>
              </div>
            </div>
          </a>
        </template>
      </div>

      <CsgPagination :perPage="perPage" :currentPage="currentPage" @currentChange="fetchIssues" :total="total" />
    </div>
  </div>
</template>
<script setup>
  import dayjs from "dayjs";
</script>
<script>
  import CsgPagination from '../shared/CsgPagination.vue'
  import { issueList } from '../../../../api/csrf/issue'
  import { Search } from '@element-plus/icons-vue'
  export default {
    data() {
      return {
        tags: ["汽车", "制造业", "能源", "通信与电子信息", "交通运输", "装备制造", "矿产与冶金", "建筑与房地产", "金融服务", "农业", "文化与出版"],
        issueTypes: [
          {
            label: '人工智能需求',
            value: 'AiIssue'
          },
          {
            label: '数字化需求',
            value: 'DigitalIssue'
          }
        ],
        activeTag: '',
        activeIssueType: '',
        keywords: '',
        perPage: 10,
        currentPage: 1,
        total: 0,
        issues: [],
        mobileScreen: window.innerWidth < 768,
        activeNavItem: '',
        drawer: false
      }
    },
    components: {
      CsgPagination,
      Search
    },
    methods: {
      handleTagClick(tag) {
        if (this.activeTag === tag) {
          this.activeTag = ''
        } else {
          this.activeTag = tag
        }
        this.fetchIssues()
      },
      handleIssueTypeClick(issueType) {
        if (this.activeIssueType === issueType) {
          this.activeIssueType = ''
        } else {
          this.activeIssueType = issueType
        }
        this.fetchIssues()
      },
      fetchIssues(page) {
        issueList({
          page: page ? page : this.currentPage,
          per: this.perPage,
          keywords: this.keywords,
          tag: this.activeTag,
          type: this.activeIssueType
        }).then(res => {
          this.issues = res.data
          this.total = res.total
        })
      },
      adapterScreenWidth() {
        this.mobileScreen = window.innerWidth < 768
      }
    },
    mounted() {
      this.fetchIssues()
      window.addEventListener("resize", this.adapterScreenWidth)
    }
  }
</script>

