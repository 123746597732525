<template>
  <div>
    <div class="text-[#101828] text-[24px] font-[500] leading-[32px] mb-[32px]">需求详情</div>
    <div class="flex flex-col gap-[32px]">
      <div>
        <div class="text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]">需求描述</div>
        <div class="text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]">
          {{ issue.description || '-' }}
        </div>
      </div>

      <div>
        <div class="text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]">需求类型</div>
        <div class="text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]">
          {{ issue.extra?.demand_list?.join('、') || '-' }}
        </div>
      </div>

      <div>
        <div class="text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]">项目概述</div>
        <div class="text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]">
          {{ issue.description || '-' }}
        </div>
      </div>

      <div>
        <div class="text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]">功能需求</div>
        <div class="text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]">
          {{ issue.extra?.feature_requirement || '-' }}
        </div>
      </div>

      <div>
        <div class="text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]">设计需求</div>
        <div class="text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]">
          {{ issue.extra?.design_requirement || '-' }}
        </div>
      </div>

      <div>
        <div class="text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]">安全和隐私要求</div>
        <div class="text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]">
          {{ issue.extra?.private_requirement || '-' }}
        </div>
      </div>

      <div>
        <div class="text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]">其他需求或备注</div>
        <div class="text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]">
          {{ issue.extra?.other_requirement || '-' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      issue: {
        type: Object,
        required: true
      }
    }
  }
</script>
