import "trix"
import "@rails/actiontext"

// Vue config
import { createApp } from "vue/dist/vue.esm-bundler.js"
import { createPinia } from 'pinia'
import refreshJWT from "./packs/refreshJWT.js"

refreshJWT()

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '../assets/stylesheets/xty-element-plus/application.css'
import { createWebHistory, createRouter } from 'vue-router'
import Vue from 'vue'

import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import { useCookies } from "vue3-cookies"
import { createI18n } from 'vue-i18n'
import en from '../../config/locales/en.js'
import zh from '../../config/locales/zh.js'


import XtyHome from "./components/xty_community/frontend/home/Index.vue"
import CommunityFrontendHeader from "./components/xty_community/frontend/header.vue"
import RankConfigurationIndex from "./components/xty_community/frontend/rank_configuration/index.vue"
import XtyNewModel from "./components/xty_community/frontend/models/NewModel.vue"
import XtyModelCards from "./components/xty_community/frontend/models/ModelCards.vue"
import XtyModelItem from "./components/xty_community/frontend/models/ModelItem.vue"
import XtyNewDataset from "./components/xty_community/frontend/datasets/NewDataset.vue"
import XtyDatasetCards from "./components/xty_community/frontend/datasets/DatasetCards.vue"
import XtyDatasetItem from "./components/xty_community/frontend/datasets/DatasetItem.vue"
import XtyDatasetDetail from "./components/xty_community/frontend/datasets/DatasetDetail.vue"
import XtyProfile from "./components/xty_community/frontend/user_settings/Profile.vue"
import XtyProfileSettings from "./components/xty_community/frontend/user_settings/ProfileSettings.vue"
import XtyProfileRepoList from "./components/xty_community/frontend/shared/ProfileRepoList.vue"
import XtyGitTokenSettings from "./components/xty_community/frontend/user_settings/GitTokenSettings.vue"
import XtySshKeySettings from "./components/xty_community/frontend/user_settings/SshKeySettings.vue"
import XtyNewOrganization from "./components/xty_community/frontend/organizations/NewOrganization.vue"
import XtyOrganizationDetail from "./components/xty_community/frontend/organizations/OrganizationDetail.vue"
import XtyOrganizationSettings from "./components/xty_community/frontend/organizations/OrganizationSettings.vue"
import XtyOrganizationCollection from "./components/xty_community/frontend/organizations/Collection.vue"
import XtySpaceIndex from "./components/xty_community/frontend/spaces/Index.vue"
import XtyDailyPaperNew from "./components/xty_community/frontend/daily_paper/New.vue"
import XtyDailyPaperIndex from "./components/xty_community/frontend/daily_paper/Index.vue"
import XtyDailyPaperShow from "./components/xty_community/frontend/daily_paper/Show.vue"
import XtySalonIndex from "./components/xty_community/frontend/salon/Index.vue"
import XtyBulletinShow from "./components/xty_community/frontend/bulletin/Show.vue"
import XtyUploadFiles from "./components/xty_community/frontend/uploads/Index.vue"
import XtyAiIssueNew from "./components/xty_community/frontend/ai_issue/New.vue"
import XtyDigitalIssueNew from "./components/xty_community/frontend/digital_issue/New.vue"
import XtyRepoDetail from "./components/xty_community/frontend/shared/RepoDetail.vue"
import XtyRepoCards from "./components/xty_community/frontend/shared/RepoCards.vue"
import SvgIcon from "./components/shared/SvgIcon.vue"
import XtyNewCode from "./components/xty_community/frontend/codes/NewCode.vue"
import XtyIssueIndex from "./components/xty_community/frontend/issues/Index.vue"
import XtyIssueShow from "./components/xty_community/frontend/issues/Show.vue"

import XtyWebnav from "./components/xty_community/frontend/home/WebNav.vue"

// Admin pages
import AdminNavbar from "./components/new_admin/AdminNavbar.vue"
import AdminMenu from "./components/new_admin/AdminMenu.vue"
import AdminDashboard from "./components/new_admin/AdminDashboard.vue"
import AdminEmailSending from "./components/new_admin/AdminEmailSending.vue"
import AdminUserList from "./components/new_admin/users/AdminUserList.vue"
import AdminUserDetail from "./components/new_admin/users/AdminUserDetail.vue"
import AdminSyncSetting from "./components/new_admin/sync/AdminSyncSetting.vue"
import AdminCodeList from "./components/new_admin/codes/AdminCodeList.vue"
import AdminCodeDetail from "./components/new_admin/codes/AdminCodeDetail.vue"
import AdminModelList from "./components/new_admin/models/AdminModelList.vue"
import AdminModelDetail from "./components/new_admin/models/AdminModelDetail.vue"
import AdminModelEdit from "./components/new_admin/models/AdminModelEdit.vue"
import AdminDatasetList from "./components/new_admin/dataset/AdminDatasetList.vue"
import AdminDatasetDetail from "./components/new_admin/dataset/AdminDatasetDetail.vue"
import AdminEmailGroup from "./components/new_admin/AdminEmailGroup.vue"
import AdminSystemConfig from "./components/new_admin/system_configs/AdminSystemConfig.vue"
// import AdminEaiArticleList from "./components/new_admin/eai_articles/AdminEaiArticleList.vue"
// import AdminEaiArticleForm from "./components/new_admin/eai_articles/AdminEaiArticleForm.vue"
// import AdminEaiArticleDetail from "./components/new_admin/eai_articles/AdminEaiArticleDetail.vue"
// import AdminEaiDeviceList from "./components/new_admin/eai_devices/AdminEaiDeviceList.vue"
// import AdminEaiDeviceForm from "./components/new_admin/eai_devices/AdminEaiDeviceForm.vue"
// import AdminEaiDeviceDetail from "./components/new_admin/eai_devices/AdminEaiDeviceDetail.vue"
// import AdminMirrorList from "./components/new_admin/mirrors/AdminMirrorList.vue"
// import AdminMirrorForm from "./components/new_admin/mirrors/AdminMirrorForm.vue"


const pinia = createPinia()

app = createApp({
  components: {
    XtyHome,
    CommunityFrontendHeader,
    RankConfigurationIndex,
    XtyNewModel,
    XtyModelCards,
    XtyModelItem,
    XtyNewDataset,
    XtyDatasetCards,
    XtyDatasetItem,
    XtyDatasetDetail,
    XtyProfile,
    XtyProfileSettings,
    XtyProfileRepoList,
    XtyGitTokenSettings,
    XtySshKeySettings,
    XtyNewOrganization,
    XtyOrganizationDetail,
    XtyOrganizationSettings,
    XtySpaceIndex,
    XtyDailyPaperNew,
    XtyDailyPaperIndex,
    XtyDailyPaperShow,
    XtyOrganizationCollection,
    XtySalonIndex,
    XtyBulletinShow,
    XtyUploadFiles,
    XtyAiIssueNew,
    XtyDigitalIssueNew,
    XtyRepoDetail,
    XtyRepoCards,
    XtyNewCode,
    XtyIssueIndex,
    XtyIssueShow,
    AdminNavbar,
    AdminMenu,
    XtyWebnav,
  },
  provide:{
    defaultTags: DEFAULT_TAGS,
    csghubServer: CSGHUB_SERVER,
    onPremise: ON_PREMISE,
    nameRule: /^(?=.{2,64}$)(?!.*[-_.]{2})[a-zA-Z][a-zA-Z0-9_.-]*[a-zA-Z0-9]+$/
  }
}).use(ElementPlus, {
  locale: zhCn,
});

// register Element UI Icons
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
const { cookies } = useCookies()
const defaultLanguage = ['zh', 'zh-cn', 'zh-tw'].includes(navigator.language.toLowerCase()) ? 'zh' : 'en';

const i18n = createI18n({
  legacy: false,
  locale: cookies.get('locale') || defaultLanguage,
  messages: {
    en,
    zh
  }
})

// register Element UI Icons
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

const routes = [
  { path: '/new_admin/', component: AdminDashboard },
  { path: '/new_admin/email_sending', component: AdminEmailSending },
  { path: '/new_admin/users', component: AdminUserList },
  { path: '/new_admin/users/:id', component: AdminUserDetail },
  { path: '/new_admin/sync', component: AdminSyncSetting },
  { path: '/new_admin/codes', component: AdminCodeList },
  { path: '/new_admin/codes/:namespace/:name', component: AdminCodeDetail },
  { path: '/new_admin/models', component: AdminModelList },
  { path: '/new_admin/models/:namespace/:name', component: AdminModelDetail },
  { path: '/new_admin/email_group', component: AdminEmailGroup },
  { path: '/new_admin/models/:namespace/:name/edit', component: AdminModelEdit },
  { path: '/new_admin/datasets', component: AdminDatasetList },
  { path: '/new_admin/datasets/:namespace/:name', component: AdminDatasetDetail },
  { path: '/new_admin/system_config', component: AdminSystemConfig },
  // { path: '/new_admin/eai_articles', component: AdminEaiArticleList },
  // { path: '/new_admin/eai_devices', component: AdminEaiDeviceList },
  // { path: '/new_admin/eai_devices/new', component: AdminEaiDeviceForm },
  // { path: '/new_admin/eai_devices/:id/edit', component: AdminEaiDeviceForm },
  // { path: '/new_admin/eai_devices/:id', component: AdminEaiDeviceDetail },
  // { path: '/new_admin/eai_articles/new', component: AdminEaiArticleForm },
  // { path: '/new_admin/eai_articles/:id/edit', component: AdminEaiArticleForm },
  // { path: '/new_admin/eai_articles/:id', component: AdminEaiArticleDetail },
  // { path: '/new_admin/mirrors', component: AdminMirrorList },
  // { path: '/new_admin/mirrors/new', component: AdminMirrorForm },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

app.component('SvgIcon', SvgIcon)
app.use(i18n)
app.use(pinia)
app.use(router)
app.mount("#community-app")
