<template>
  <div class="w-full md:px-5">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>
        <a :href="props.referActionUrl">
          {{props.referActionName}}
        </a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{props.currentActionName}}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script setup>
  const props = defineProps({
    referActionUrl: String,
    referActionName: String,
    currentActionName: String,
  });

  console.log(props.referActionUrl, props.referActionName, props.currentActionName);
</script>