<template>
  <div class="grid grid-cols-4 gap-4 px-4 py-3">
    <div
      v-for="(item, idx) in items"
      :key="idx"
      class="bg-white border border-gray-200 rounded-xl shadow-md overflow-hidden cursor-pointer transition hover:shadow-lg relative group"
      @click="openweb(item.url)"
    >
      <div class="p-4 h-full flex flex-col">
        <div class="flex items-center space-x-2 mb-2">
          <img
            :src="item.logo"
            :alt="item.title"
            class="w-8 h-8 rounded-full object-cover flex-shrink-0"
          />
          <h4 class="text-gray-800 font-semibold text-sm truncate flex-grow">{{ item.title }}</h4>
        </div>
        <p class="text-gray-600 text-xs line-clamp-1 flex-grow">{{ item.desc }}</p>
      </div>
      <div class="absolute inset-0 bg-black bg-opacity-75 text-white p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 overflow-y-auto">
        <p class="text-sm">{{ item.desc }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebItem',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    openweb(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped>
.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>