<template>
  <div
    class="flex items-center gap-4 absolute top-0 right-0 md:relative md:pl-5 md:pb-4 z-10 md:hidden"
  >
    <a
      class="rounded border border-[#DCDFE6] flex items-center justify-center px-4 py-[5px] mx-[16px] cursor-pointer hover:bg-[#F9FAFB]"
      @click="downloadBtLink"
      v-if="!!ptMetadata?.metadata?.data?.stuts == 1">
      <img class="w-[16px] h-[16px]" src="/images/xty_images/common/magnet.svg" alt="">
      <div class="ml-2 text-[#606266] text-sm font-medium">BT 下载</div>
    </a>
    <!-- multi-source sync button -->
    <el-button
      v-if="showSyncButton"
      type="default"
      class="!rounded-lg"
      :disabled="syncInprogress"
      @click="handleSyncRepo"
    >
      <SvgIcon name="sync" class="mr-2" />
      {{ syncInprogress ? $t("repo.source.syncing") : $t("repo.source.syncButton") }}
    </el-button>

    <!-- endpoint deploy button -->
    <!-- <DeployDropdown
      v-if="isLoggedIn && repoType === 'model' && enableEndpoint && !!httpCloneUrl"
      :modelId="namespacePath"
    /> -->
    <div v-if="!isLoggedIn && repoType === 'model' && enableEndpoint && !!httpCloneUrl">
      <el-button type="default" class="!rounded-lg" @click="toLoginPage">
        {{ $t("all.deploy") }}
        <el-icon class="ml-1 el-icon--right">
          <arrow-down />
        </el-icon>
      </el-button>
    </div>

    <!-- finetune deploy button -->
    <div
      class="flex px-[12px] py-[5px] mr-4 justify-center items-center gap-1 rounded-lg bg-[#FFF] border border-[#D0D5DD] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] cursor-pointer"
      v-if="repoType === 'model' && enableFinetune && !!httpCloneUrl"
      @click="handleButtonClick"
    >
      <SvgIcon
        name="model_finetune_create"
        class="mr-1"
      />
      <div class="text-sm">{{ $t('finetune.title') }}</div>
    </div>

    <!-- repo download clone button -->
    <div
      v-if="!!httpCloneUrl"
      class="rounded border border-[#DCDFE6] flex items-center justify-center px-4 py-[5px] cursor-pointer hover:bg-[#F9FAFB]"
      @click="cloneRepositoryVisible = true"
    >
      <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <rect opacity="0.12" x="1.3335" y="1.3335" width="13.3333" height="13.3333" rx="3.33333" fill="#2F384C"/>
        <path d="M6.66683 5.3335L4.00016 8.00016L6.66683 10.6668M9.3335 5.3335L12.0002 8.00016L9.3335 10.6668M6.66682 14.6668H9.3335C11.2003 14.6668 12.1338 14.6668 12.8468 14.3035C13.474 13.9839 13.9839 13.474 14.3035 12.8468C14.6668 12.1338 14.6668 11.2003 14.6668 9.3335V6.66683C14.6668 4.79999 14.6668 3.86657 14.3035 3.15353C13.9839 2.52632 13.474 2.01639 12.8468 1.69681C12.1338 1.3335 11.2003 1.3335 9.3335 1.3335H6.66683C4.79999 1.3335 3.86657 1.3335 3.15353 1.69681C2.52632 2.01639 2.01639 2.52632 1.69681 3.15353C1.3335 3.86657 1.3335 4.79999 1.3335 6.66682V9.3335C1.3335 11.2003 1.3335 12.1338 1.69681 12.8468C2.01639 13.474 2.52632 13.9839 3.15353 14.3035C3.86657 14.6668 4.79999 14.6668 6.66682 14.6668Z" stroke="#2F384C" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <div class="ml-2 text-[#606266] text-sm font-medium">Git 下载</div>
    </div>

    <!-- clone dialog -->
    <el-dialog
      v-model="cloneRepositoryVisible"
      title=""
      class="md:!w-[80%]"
    >
      <div class="rounded-t border-t border-x border-[#EBEEF5] mt-4">
        <div class="flex items-center px-3 py-2 bg-[#F5F7FA] text-[#303133]">
          <SvgIcon
            name="clone"
            class="mr-1"
          />
          {{ $t(downloadModalKey) }}
        </div>
      </div>
      <el-tabs
        v-model="activeCloneType"
        class="border border-[#EBEEF5] mb-8 clone-tabs"
      >
        <el-tab-pane
          label="HTTPS"
          name="https"
        >
          <div
            class="flex flex-col gap-1 px-3 py-2 border-t border-[#EBEEF5] bg-[#ffffff] text-[#303133] break-all"
          >
            <div class="flex gap-[8px] text-[14px] leading-[20px] text-[#667085]">
              <SvgIcon name="exclamation_point" width="13" height="13" class="cursor-pointer" />
              Use
              <a href="/settings/git-token" target="_blank" class="underline">access token</a>
              as git password/credential
            </div>
            <div
              class="mb-[4px]"
              v-if="currentUser"
            >
              <el-checkbox
                v-model="useToken"
                :label="$t('application_spaces.gradioGuide.useToken')"
                size="large"
              />
            </div>
            <div class="text-[#909399]"># {{ $t('all.lfsTips') }}</div>
            <markdown-viewer
              v-if="useToken"
              :content="httpsCloneCodeWithTokenMarkdown"
            ></markdown-viewer>
            <markdown-viewer
              v-else
              :content="httpsCloneCodeMarkdown"
            ></markdown-viewer>
            <div class="text-[#909399]"># {{ $t('all.lfsTips2') }}</div>
            <markdown-viewer
              :content="getMarkdownCode('  GIT_LFS_SKIP_SMUDGE=1', 'bash')"
            ></markdown-viewer>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="SSH"
          name="ssh"
        >
          <div
            class="flex flex-col gap-1 px-3 py-2 border-t border-[#EBEEF5] bg-[#ffffff] text-[#303133] break-all"
          >
            <div class="flex gap-[8px] text-[14px] leading-[20px] text-[#667085] mb-[8px]">
              <SvgIcon name="exclamation_point" width="13" height="13" class="cursor-pointer" />
              <a href="/settings/ssh-keys" target="_blank" class="underline">Add your SSH public key</a>
            </div>
            <div class="text-[#909399]"># {{ $t('all.lfsTips') }}</div>
            <markdown-viewer :content="sshCloneCodeMarkdown"></markdown-viewer>
            <div class="text-[#909399]"># {{ $t('all.lfsTips2') }}</div>
            <markdown-viewer
              :content="getMarkdownCode('  GIT_LFS_SKIP_SMUDGE=1', 'bash')"
            ></markdown-viewer>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane
          v-if="repoType == 'model' || repoType == 'dataset'"
          label="SDK"
          name="sdk"
        >
          <div
            class="flex flex-col gap-1 px-3 py-2 border-t border-[#EBEEF5] bg-[#ffffff] text-[#303133] break-all"
          >
          <div class="flex gap-[8px] text-[14px] leading-[20px] text-[#667085]">
            <SvgIcon name="exclamation_point" width="13" height="13" class="cursor-pointer" />
            Use
            <a href="https://github.com/CAICTs/csghub-sdk" target="_blank" class="underline"> SDK </a>
            to download
            </div>
            <div class="text-[#909399] mt-[8px]"># {{ $t('all.sdkTips') }}</div>
            <markdown-viewer
              :content="cmdCloneCodeMarkdown"
            ></markdown-viewer>
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script setup>
  import { computed, ref, onMounted, watch } from 'vue'
  import MarkdownViewer from '../shared/viewers/MarkdownViewer.vue'
  import SvgIcon from './SvgIcon.vue'
  import { useCookies } from 'vue3-cookies'
  import useFetchApi from '../../../../packs/useFetchApi'
  import { ElMessage } from "element-plus"
  import csrfFetch from "../../../../packs/csrfFetch"
  
  const { cookies } = useCookies()

  const props = defineProps({
    repoType: String,
    userName: String,
    namespacePath: String,
    admin: Boolean,
    repo: Object,
    enableEndpoint: Boolean,
    enableFinetune: Boolean,
  })

  const httpCloneUrl = ref('')
  const sshCloneUrl = ref('')
  const httpCloneProtocol = ref('https:')

  const httpsCloneCode = ref('')
  const sshCloneCode = ref('')
  const httpsCloneCodeWithToken = ref('')
  const isLoggedIn =ref(false)
  watch(() => props.repo, () => {
    const url = new URL(props.repo.repository.http_clone_url)
    httpCloneUrl.value = props.repo.repository.http_clone_url
    httpCloneProtocol.value = url.protocol
    sshCloneUrl.value = props.repo.repository.ssh_clone_url
// no space
    httpsCloneCode.value = `
git lfs install
git clone ${httpCloneUrl.value}
`
    // no space
    sshCloneCode.value = `
git lfs install
git clone ${sshCloneUrl.value}
`
  // no space
  httpsCloneCodeWithToken.value = `
git lfs install
git clone ${httpCloneProtocol.value}//${
    currentUser.value
  }:${accessToken.value}@${httpCloneUrl.value.replace('https://', '').replace('http://', '')}
`
  })

  const activeCloneType = ref('https')
  const cloneRepositoryVisible = ref(false)
  const useToken = ref(false)
  const currentUser = ref(cookies.get('current_user'))
  const accessToken = ref('')
  const ptMetadata = ref({})


  const showSyncButton = computed(() =>
    props.admin &&
    props.repo.source === 'caict' &&
    ['pending', 'inprogress', 'failed'].includes(props.repo.sync_status)
  )

  // 同步按钮禁用
  const syncInprogress = computed(() => {
    return props.repo.source === 'caict' && props.repo.sync_status === 'inprogress'
  })

  const getMarkdownCode = (code, lang, multiline = false) => {
    return `\`\`\`${lang}${multiline ? '' : '\n'}${code}${
      multiline ? '' : '\n'
    }\`\`\``
  }

  watch(accessToken, async (newAccessToken) => {
    // no space
    httpsCloneCodeWithToken.value = `
git lfs install
git clone ${httpCloneProtocol.value}//${
    currentUser.value
  }:${newAccessToken}@${httpCloneUrl.value.replace('https://', '').replace('http://', '')}
`
  })

  const httpsCloneCodeMarkdown = computed(() => {
    return getMarkdownCode(httpsCloneCode.value, 'bash', true)
  })

  const httpsCloneCodeWithTokenMarkdown = computed(() => {
    return getMarkdownCode(httpsCloneCodeWithToken.value, 'bash', true)
  })

  const sshCloneCodeMarkdown = computed(() => {
    return getMarkdownCode(sshCloneCode.value, 'bash', true)
  })

  const getCmdCloneCode = () => {
    return ref(`
from pycsghub.snapshot_download import snapshot_download
token = '' # token from caict.com
endpoint = "https://hub.caict.com"
repo_type = "${props.repoType}"
repo_id = '${props.namespacePath}'
cache_dir = '' # cache dir of download data
result = snapshot_download(repo_id, cache_dir=cache_dir, endpoint=endpoint, token=token, repo_type=repo_type)
`)
  }

  const cmdCloneCodeMarkdown = computed(() => {
    const cmdCloneCode = getCmdCloneCode()
    return getMarkdownCode(cmdCloneCode.value, 'bash', true)
  })

  const downloadButtonKey = computed(() => {
    switch (props.repoType) {
      case 'dataset':
        return 'datasets.downloadDataset'
      case 'model':
        return 'models.download'
      case 'code':
        return 'codes.downloadCode'
      case 'space':
        return 'application_spaces.download'
      default:
        return ''
    }
  })

  const downloadModalKey = computed(() => {
    switch (props.repoType) {
      case 'dataset':
        return 'datasets.downloadDataset'
      case 'model':
        return 'models.downloadModel'
      case 'code':
        return 'codes.downloadCode'
      case 'space':
        return 'application_spaces.downloadSpace'
      default:
        return ''
    }
  })

  function toFinetunePage() {
    window.location.href = `/finetune/new?model_id=${props.namespacePath}&repoType=${props.repoType}`
  }

  const fetchUserToken = async() => {
    if (!currentUser.value) return
    let userName = props.userName
    if (!userName) {
      userName = currentUser.value
    }
    if (!userName) return

    const { data } = await useFetchApi(
      `/user/${currentUser.value}/tokens?app=git`
    ).json()
    if (data.value) {
      const body = data.value
      if (body.data) {
        accessToken.value = body.data[0].token
      }
    }
  }

  const downloadBtLink =() => {
    let repoType = ""
    if (props.repoType == "model") {
      repoType = "models"
    }
    if (props.repoType == "dataset") {
      repoType = "datasets"
    }
    const path = window.location.pathname.split('/').slice(2).join('/')

    const incr_downloads_url = `/internal_api/${repoType}/${path}/incr_downloads`
    const option = {method: 'PUT'}
    csrfFetch(incr_downloads_url, option)

    const download_url = `/${repoType}/${path}/pt_download`
    window.open(download_url);
  };

  const fetchMetadata = async () => {
    let repoType = ""
    if (props.repoType == "model") {
      repoType = "models"
    }
    if (props.repoType == "dataset") {
      repoType = "datasets"
    }
    const path = window.location.pathname.split('/').slice(2, 4).join('/')
    const url = `/internal_api/${repoType}/${path}/pt_metadata`
    const option = {method: 'GET'}
    await csrfFetch(url, option)
      .then(response => response.json())
      .then(data => {
        ptMetadata.value = data
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }


  const handleSyncRepo =  async () => {
    const syncUrl = `/${props.repoType}s/${props.namespacePath}/mirror_from_saas`
    const { error } = await useFetchApi(syncUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    }).post().json()

    if (error.value) {
      ElMessage({message: error.value.msg, type: "warning"})
    } else {
      ElMessage({message: 'Sync repo success', type: "success"})
      setTimeout(() => {
        location.reload()
      }, 2000)
    }
  }

  const handleButtonClick = () =>{
    if(isLoggedIn.value){
      toFinetunePage()
    }else{
      toLoginPage()
    }
  }
  const toLoginPage = () => {
    window.location.href = '/login'
  }

  onMounted(() => {
    isLoggedIn.value = !!currentUser.value;
    fetchUserToken()
    fetchMetadata()
  })
</script>
