import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-[8px] w-[260px] p-[20px] border rounded-[10px] bg-[#F4F4F6]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_RouterLink = _resolveComponent("RouterLink")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RouterLink, {
      to: "/new_admin/",
      class: "underline"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('admin.dashboard.title')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" <RouterLink\n      to=\"/new_admin/system_config\"\n      class=\"underline\"\n    >\n      System Config\n    </RouterLink> "),
    _createVNode(_component_RouterLink, {
      to: "/new_admin/users",
      class: "underline"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Users ")
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_RouterLink, {
      to: "/new_admin/models",
      class: "underline"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Models ")
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_RouterLink, {
      to: "/new_admin/datasets",
      class: "underline"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Datasets ")
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_RouterLink, {
      to: "/new_admin/codes",
      class: "underline"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Codes ")
      ]),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" <RouterLink\n      to=\"/new_admin/mirrors\"\n      class=\"underline\"\n    >\n      Mirrors\n    </RouterLink> "),
    ($setup.onPremise === 'true')
      ? (_openBlock(), _createBlock(_component_RouterLink, {
          key: 0,
          to: "/new_admin/sync",
          class: "underline"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Sync Setting ")
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" <RouterLink\n      v-if=\"onPremise === 'false'\"\n      to=\"/new_admin/email_sending\"\n      class=\"underline\"\n    >\n      Email Sending\n    </RouterLink>\n\n    <RouterLink\n      v-if=\"onPremise === 'false'\"\n      to=\"/new_admin/email_group\"\n      class=\"underline\"\n    >\n      Email Group\n    </RouterLink> "),
    _createCommentVNode(" <RouterLink\n      to=\"/new_admin/eai_articles\"\n      class=\"underline\"\n    >\n      EAI Articles\n    </RouterLink>\n    <RouterLink\n      to=\"/new_admin/eai_devices\"\n      class=\"underline\"\n    >\n      EAI Devices\n    </RouterLink> ")
  ]))
}