<template>
  <div class="max-w-[1440px] m-auto flex">
    <!-- <div class='w-[240px]'>
      nengli 
    </div> -->
    <div class="w-full md:px-[16px]">
      <div class="flex md:justify-between community-border-b md:gap-[unset] gap-[16px]">
        <div class="md:px-[0px] px-[16px] py-[12px] w-[168px] css-custom-input">
          <el-date-picker
            type="month"
            v-model="searchForm.version"
            placeholder="选择月份"
            format="YYYY年MM月"
            clear-icon=""
            @change="queryByVersion"
          />
        </div>
        <div class="md:px-[0px] px-[16px] py-[12px] w-[168px] css-custom-input">
          <el-select
              v-model="localSearchForm.deployment_methods"
              multiple
              collapse-tags
              collapse-tags-tooltip
              placeholder="部署方式"
              style="width: 168px"
              @change="queryByLocalSearchForm"
            >
              <el-option
                v-for="item in deployment_method_options"
                :key="item"
                :label="item"
                :value="item"
              />
          </el-select>
        </div>
        <div class="md:hidden px-[16px] py-[12px] w-[168px] css-custom-input">
          <el-select
              v-model="localSearchForm.input_modals"
              multiple
              collapse-tags
              collapse-tags-tooltip
              placeholder="输入模态种类"
              style="width: 168px"
              @change="queryByLocalSearchForm"
            >
              <el-option
                v-for="item in input_modal_options"
                :key="item"
                :label="item"
                :value="item"
              />
          </el-select>
        </div>
        <div class="md:hidden px-[16px] py-[12px] w-[168px] css-custom-input">
          <el-select
              v-model="localSearchForm.output_modals"
              multiple
              collapse-tags
              collapse-tags-tooltip
              placeholder="输出模态种类"
              style="width: 168px"
              @change="queryByLocalSearchForm"
            >
              <el-option
                v-for="item in output_modal_options"
                :key="item"
                :label="item"
                :value="item"
              />
          </el-select>
        </div>

      </div>
      <div class="flex custom-table">
        <el-table
          :data="rankListsData"
          :default-sort="{ prop: 'date', order: 'descending' }"
          :show-overflow-tooltip="true"
        >
          <el-table-column fixed prop="rank" label="排名" width="80">
            <template #default="scope">
              <champion-icon class="m-auto" v-if="scope.row.rank == 1" />
              <runner-up-icon class="m-auto" v-else-if="scope.row.rank == 2" />
              <third-place-icon
                class="m-auto"
                v-else-if="scope.row.rank == 3"
              />
              <div
                v-else
                class="text-center opacity-[.26] text-[18px] font-[500] leading-[24px] tracking-[0.018px]"
              >
                {{ scope.row.rank }}
              </div>
              <div class="flex justify-center">
                <span v-if="scope.row.last_rank">
                  <span
                    class="red-filter flex gap-[4px] items-center"
                    v-if="scope.row.last_rank - scope.row.rank > 0"
                  >
                    <triangle />
                    <span
                      class="text-[12px] leading-[18px] tracking-[0.018px]"
                      >{{ scope.row.last_rank - scope.row.rank }}</span
                    >
                  </span>
                  <span
                    class="green-filter flex gap-[4px] items-center"
                    v-else-if="scope.row.last_rank - scope.row.rank < 0"
                  >
                    <triangle class="rotate-180" />
                    <span
                      class="text-[12px] leading-[18px] tracking-[0.018px]"
                      >{{ -(scope.row.last_rank - scope.row.rank) }}</span
                    >
                  </span>
                  <span v-else>
                    <span class="text-[12px] leading-[18px] tracking-[0.018px]"
                      >-</span
                    >
                  </span>
                </span>
                <span v-else> - </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" :width="mobileScreen ? 160 : 336" label="模型名称">
            <template #default="scope">
              <span class="font-[500] tracking-[0.014px]">{{
                scope.row.name
              }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="score" width="212" label="得分">
            <template #default="scope">
              <span class="font-[700] tracking-[0.014px]">{{
                scope.row.score
              }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="deployment_methods"
            width="212"
            label="部署方式"
          >
            <template #default="scope">
              <div class="flex gap-[8px]">
                <span
                  :class="`rank-tag ${deployment_method_color_map[dl]}`"
                  v-for="dl in scope.row.deployment_methods"
                  :key="dl"
                  >{{ dl }}</span
                >
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="input_modals"
            width="208"
            label="输入模态种类"
          >
            <template #default="scope">
              <div class="flex gap-[8px]">
                <span
                  class="rank-tag"
                  v-for="im in scope.row.input_modals"
                  :key="im"
                  type="info"
                  >{{ im }}</span
                >
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="output_modals"
            width="208"
            label="输出模态种类"
          >
            <template #default="scope">
              <div class="flex gap-[8px]">
                <span
                  class="rank-tag"
                  v-for="om in scope.row.output_modals"
                  :key="om"
                  type="info"
                  >{{ om }}</span
                >
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="parameter_categories"
            width="212"
            label="参数规模种类"
          >
            <template #default="scope">
              <div class="flex">
                <el-popover :width="200" trigger="hover">
                  <div class="flex flex-wrap gap-[8px]">
                    <span
                      class="rank-tag whitespace-nowrap"
                      v-for="pc in scope.row.parameter_categories"
                      :key="pc"
                    >
                      {{ pc }}
                    </span>
                  </div>
                  <template #reference>
                    <span class="underline leading-[20px] orange-hover">{{
                      scope.row.parameter_categories.length
                    }}</span>
                  </template>
                </el-popover>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="parameter_categories"
            width="212"
            label="相关论文数量"
          >
            <template #default="scope">
              <div class="flex">
                <el-popover :width="240" trigger="hover">
                  <div class="flex flex-wrap" >
                    <el-tooltip
                      class="box-item"
                      v-for="paper in scope.row.papers"
                      :key="paper"
                      effect="light"
                      :content="paper.title"
                      placement="top-start"
                    >
                      <a
                        class="my-[12px] line-clamp w-full hover:cursor-pointer hover:text-[#FF5005]"
                        :href="paper.href"
                        target="_blank"
                      >
                        《{{ paper.title }}》
                      </a>
                    </el-tooltip>
                  </div>
                  <template #reference>
                    <span class="underline leading-[20px] orange-hover">{{
                      scope.row.papers_count
                    }}</span>
                  </template>
                </el-popover>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="citations_of_papers_count"
            width="212"
            label="相关论文引用量"
            sortable
          />
          <el-table-column
            v-for="option in JSON.parse(props.import_dynamic_fields_options)"
            :key="option.field"
            :prop="option.field"
            :label="option.label"
            sortable
            width="212"
          />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script setup>
import ChampionIcon from "../../icons/ChampionIcon.vue";
import RunnerUpIcon from "../../icons/RunnerUpIcon.vue";
import ThirdPlaceIcon from "../../icons/ThirdPlaceIcon.vue";
import Triangle from "../../icons/Triangle.vue";

import { reactive, ref, onMounted} from "vue";
import dayjs from "dayjs";

const props = defineProps({
  rank_lists: String,
  version: String,
  import_dynamic_fields_options: String,
});

const rankListsData = ref(JSON.parse(props.rank_lists));

const searchForm = ref({
  version: props.version,
});

const deployment_method_options = new Set();
const input_modal_options = new Set();
const output_modal_options = new Set();

if (rankListsData.value.length > 0) {
  rankListsData.value.forEach((item) => {
    item.deployment_methods.forEach((dm) => {
      deployment_method_options.add(dm);
    });
    item.input_modals.forEach((im) => {
      input_modal_options.add(im);
    });
    item.output_modals.forEach((om) => {
      output_modal_options.add(om);
    });
  });
}

const localSearchForm = reactive({
  deployment_methods: "",
  input_modals: "",
  output_modals: "",
});


const mobileScreen = ref(
  window.innerWidth < 768
);

const adapterScreenWidth = () => {
  mobileScreen.value = window.innerWidth < 768;
};

const onWindowResize = onMounted(() => {
  window.addEventListener("resize", adapterScreenWidth);
});

const queryByLocalSearchForm = () => {
  let rankListsDataTemp = JSON.parse(props.rank_lists);
  ["deployment_methods", "input_modals", "output_modals"].forEach((key) => {
    if (localSearchForm[key].length > 0) {
      rankListsDataTemp = rankListsDataTemp.filter((item) => {
        return localSearchForm[key].every((value) => {
          return item[key].includes(value);
        });
      });
    }
  });

  rankListsData.value = rankListsDataTemp;
};

const deployment_method_color_map = {
  本地私有化部署: "tag-primary",
  云端API部署: "tag-success",
};

const queryByVersion = () => {
  window.location.href = `?version=${dayjs(searchForm.value.version).format(
    "YYYY-MM-DD"
  )}`;
};
</script>
