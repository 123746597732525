<template>
  <div class="w-full md:w-[230px]">
    <el-menu
      :default-active="activeIndex"
      class="xty-menu md:!h-[44px] md:justify-around !border-b-0"
      :menu-trigger="isMobile ? 'click' : 'hover'"
      mode="horizontal"
      :ellipsis-icon="MoreFilled"
      active-text-color="#FF5005"
      text-color="#000000e0"
      style="--el-menu-hover-bg-color: #FF500530; --el-menu-hover-text-color: #FF5005; --el-menu-bg-color: transparent;"
      @select="handleSelect"
    >
      <template :key="item.index" v-for="item in items">
        <template v-if="item.subs">
          <el-sub-menu :index="item.index">
            <template #title>{{ item.title }}</template>
            <template :key="sub.index" v-for="sub in item.subs">
              <!-- 三级菜单 -->
              <template v-if="sub.subs">
                <el-sub-menu :index="sub.index">
                  <template #title>{{ sub.title }}</template>
                  <template :key="ssub.index" v-for="ssub in sub.subs">
                    <el-menu-item :index="ssub.index" :disabled="ssub.nonclickable" style="--el-menu-hover-bg-color: #FF500530; --el-menu-hover-text-color: #FF5005;">
                      {{ ssub.title }}
                    </el-menu-item>
                  </template>
                </el-sub-menu>
              </template>
              <!-- 二级菜单 -->
              <template v-else>
                <el-menu-item :index="sub.index" :disabled="sub.nonclickable" style="--el-menu-hover-bg-color: #FF500530; --el-menu-hover-text-color: #FF5005;">
                  {{ sub.title }}
                </el-menu-item>
              </template>
            </template>
          </el-sub-menu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :disabled="item.nonclickable">
            {{ item.title }}
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>
<script setup>
  import { MoreFilled } from '@element-plus/icons-vue'
</script>

<script>

export default {
  props: {
    menu: {
      type: Object,
    }
  },
  data() {
    return {
      items: this.menu,
      activeIndex: '/',
      isMobile: false
    }
  },
  created() {
    this.parseActiveIndex()
  },
  mounted() {
    this.isMobile = window.innerWidth < 768
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 768
    })
  },
  watch: {
    menu(val) {
      this.items = val
    }
  },
  methods: {
    parseActiveIndex() {
      const path = window.location.pathname
      this.activeIndex = path
    },
    handleSelect(index, indexPath) {
      const currentDomain = window.location.hostname;
      const tempLink = document.createElement('a');
      tempLink.href = index;
      
      const targetDomain = tempLink.hostname;

      if (targetDomain === currentDomain) {
        window.location.href = index;
      } else {
        window.open(index, '_blank');
      }
    }
  }
}
</script>