import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e4281375"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid grid-cols-4 gap-4 px-4 py-3" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "p-4 h-full flex flex-col" }
const _hoisted_4 = { class: "flex items-center space-x-2 mb-2" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "text-gray-800 font-semibold text-sm truncate flex-grow" }
const _hoisted_7 = { class: "text-gray-600 text-xs line-clamp-1 flex-grow" }
const _hoisted_8 = { class: "absolute inset-0 bg-black bg-opacity-75 text-white p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 overflow-y-auto" }
const _hoisted_9 = { class: "text-sm" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        key: idx,
        class: "bg-white border border-gray-200 rounded-xl shadow-md overflow-hidden cursor-pointer transition hover:shadow-lg relative group",
        onClick: $event => ($options.openweb(item.url))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: item.logo,
              alt: item.title,
              class: "w-8 h-8 rounded-full object-cover flex-shrink-0"
            }, null, 8 /* PROPS */, _hoisted_5),
            _createElementVNode("h4", _hoisted_6, _toDisplayString(item.title), 1 /* TEXT */)
          ]),
          _createElementVNode("p", _hoisted_7, _toDisplayString(item.desc), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, _toDisplayString(item.desc), 1 /* TEXT */)
        ])
      ], 8 /* PROPS */, _hoisted_2))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}