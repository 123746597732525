import request from './request'

/** 需求 开始 */
// 搜索需求
export function issueList(query) {
  return request({
    url: `/internal_api/issues`,
    method: 'get',
    params: query
  })
}

// 查看需求
export function issueDetail(uuid) {
  return request({
    url: `/internal_api/issues/${uuid}`,
    method: 'get',
  })
}
/** 需求 结束 */
