<template>
  <div class="bg-[#F4F5FE] pt-[24px] pb-[40px]">
    <div class="flex flex-col max-w-[1440px] mx-[32px] gap-[24px] md:mx-[10px]">
      <div class="flex flex-col w-full bg-white p-[32px] rounded-[8px] gap-[20px] md:px-[16px]">
        <div class="flex items-center gap-[8px] text-[14px] font-[400] leading-[20px]">
          <SvgIcon width="20" height="20" name="xty_home" />
          <SvgIcon width="20" height="20" name="slash" />
          <div class="text-[#475467]">企业需求</div>
          <SvgIcon width="20" height="20" name="slash" />
          <div class="text-[#344054]">技术需求</div>
        </div>

        <div class="flex gap-[8px] font-[500] items-center">
          <a href="/issues" class="flex items-center">
            <el-icon :size="24"><Back /></el-icon>
          </a>
          <div class="text-[#101828] text-[30px] font-[500] leading-[30px]">
            {{ issue.project_name }}
          </div>
        </div>

        <div class="my-[12px] text-[14px] font-[400] leading-[22px] flex flex-col gap-[8px]">
          <div class="">
            <span class="text-[#475467]">企业名称：</span>
            <span class="text-[#667085]">{{ issue.org_name }}</span>
          </div>
          <div class="">
            <span class="text-[#475467]">产业链：</span>
            <span class="text-[#667085]">{{ issue.industry_list.join('、') }}</span>
          </div>
          <div class="">
            <span class="text-[#475467]">联系人：</span>
            <span class="text-[#667085]">{{ issue.extra?.contact_name }}</span>
            <span class="text-[#667085]">（{{ issue.extra?.contact_phone }}）</span>
          </div>
        </div>

        <div class="flex md:flex-col gap-[16px]">
          <div class="flex w-[320px] md:w-full bg-[#fbfafa] rounded-[8px] shadow-[0px_11px_40px_0px_rgba(0,0,0,0.05)] py-[20px]">
            <img src="/images/xty_images/issue/budget.png" class="h-[52px]" alt="">
            <div class="flex flex-col">
              <div class="text-[#FF8035] text-[18px] font-[500] leading-[28px]">
                <template v-if="issue.budget > 0">
                  <div class="">{{ issue.budget }}万</div>
                </template>
                <template v-else>
                  <div class="">面议</div>
                </template>
              </div>
              <div class="text-[#667085] text-[14px] font-[400] leading-[20px]">
                预计投入金额
              </div>
            </div>
          </div>
          <div class="flex w-[320px] md:w-full bg-[#fbfafa] rounded-[8px] shadow-[0px_11px_40px_0px_rgba(0,0,0,0.05)] py-[20px]">
            <img src="/images/xty_images/issue/delivery.png" class="h-[52px]" alt="">
            <div class="flex flex-col">
              <div class="text-[#182230] text-[16px] font-[500] leading-[24px]">
                {{ issue.delivery_at ? dayjs(issue.delivery_at).format("YYYY-MM-DD") : '面议' }}
              </div>
              <div class="text-[#667085] text-[14px] font-[400] leading-[20px]">
                截止时间
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full bg-white p-[32px] rounded-[8px] gap-[20px] md:px-[16px]">
        <DigitalIssue v-if="issue.type === 'DigitalIssue'" :issue="issue" />
        <AiIssue v-else :issue="issue" />
      </div>

    </div>
  </div>
</template>

<script setup>
  import dayjs from "dayjs";
</script>
<script>
import { issueDetail } from '../../../../api/csrf/issue'
import DigitalIssue from './DigitalIssue.vue'
import AiIssue from './AiIssue.vue'

export default {
  data() {
    return {
      issue: {
        industry_list: [],
        extra: {}
      }
    }
  },
  components: {
    DigitalIssue,
    AiIssue
  },
  mounted() {
    this.fetchIssue()
  },
  methods: {
    fetchIssue() {
      const id = location.pathname.split('/')[2]
      issueDetail(id).then(res => {
        this.issue = res.data
      })
    }
  }
}
</script>
